import { Filter } from '@pages/MetricPage/utils/state.types';
import { FilterOutline16 } from '@icons/index';
import { DrilldownCard } from '../DrillDownCard';
import { useCallback, useMemo, useRef } from 'react';
import { useIsFiltersV2Enabled } from '@pages/MetricPage/components/FiltersAndBreakdown/useIsFiltersV2Enabled';
import {
	formatDimensionLabel,
	formatMetricFilterLabel,
	replaceLastCommaWithOr,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { useMetricFilter } from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/useMetricFilter';
import { useReportEvent } from '@services/analytics';
import { Operator } from './Operator';
import { DropdownStringEditor } from '../Editors/DropdownStringEditor';

export const FilterDrilldownCard = ({
	filter,
	onRemove,
	onClick,
	isEditorAutoOpened,
	eventMetadata,
}: {
	filter: Filter;
	onRemove?: VoidFunction;
	onClick?: VoidFunction;
	isEditorAutoOpened?: boolean;
	eventMetadata: object;
}) => {
	const { reportEvent } = useReportEvent();
	const isFiltersV2Enabled = useIsFiltersV2Enabled();
	const { label, values } = filter;
	const formattedLabel = formatDimensionLabel(label);
	const initialFocusRef = useRef<{ focus(): void }>({ focus: () => {} });

	const { isLoadingDimensionValues, options, updateFilter, semanticDefinitionsForEntityDimension } = useMetricFilter({
		filter,
	});

	const extendedEventMetadata = {
		...eventMetadata,
		dimension: filter?.key,
		dimensionType: semanticDefinitionsForEntityDimension?.type,
	};

	const texts = useMemo(() => {
		if (isFiltersV2Enabled) {
			const firstCommaSeparatedValuesLabels = values?.map(formatMetricFilterLabel).join(', ');
			const displayValue = values && replaceLastCommaWithOr(firstCommaSeparatedValuesLabels);

			return {
				title: formattedLabel,
				tooltip: displayValue ? firstCommaSeparatedValuesLabels : formattedLabel,
				displayValue,
			};
		} else {
			const valuesSelectedCount = values?.length;
			const maxValuesToDisplay = 5;
			const extraMissingValues =
				valuesSelectedCount && valuesSelectedCount > maxValuesToDisplay
					? `, +${valuesSelectedCount - maxValuesToDisplay} more`
					: '';
			const firstCommaSeparatedValues = values
				?.slice(0, maxValuesToDisplay)
				.map((v) => v?.toString() || '')
				.join(', ');

			const displayValue = (values && firstCommaSeparatedValues) ?? '';
			const title = values ? `${label} (${values.length})` : label;

			return {
				title,
				tooltip: values ? `${firstCommaSeparatedValues}${extraMissingValues}` : label,
				displayValue,
			};
		}
	}, [values, label, formattedLabel, isFiltersV2Enabled]);

	const { title, tooltip, displayValue } = texts;

	// TODO: implement icon logic based on filter type
	const icon = useMemo(() => {
		return <FilterOutline16 />;
	}, []);

	// TA1.0
	const onEdit = () => {
		reportEvent({ event: 'filters-edit-values', metaData: extendedEventMetadata });
		onClick?.();
	};

	const onApply = useCallback(
		(values: (string | null)[]) => {
			updateFilter(values);
		},
		[updateFilter]
	);

	return (
		<DrilldownCard
			icon={icon}
			onRemove={onRemove}
			removeTooltip={'Remove filter'}
			title={title}
			eventMetadata={extendedEventMetadata}
			valueEditor={
				<DropdownStringEditor
					options={options}
					displayValue={displayValue}
					values={values}
					onEdit={onEdit}
					onApply={onApply}
					isEnabled={isFiltersV2Enabled}
					applyButtonLabel={'Add'}
					prefixComponent={<Operator operator={'Is'} />}
					defaultIsEditing={isEditorAutoOpened}
					tooltip={isFiltersV2Enabled ? 'Edit values' : tooltip}
					isLoadingOptions={isLoadingDimensionValues}
					placeholder={'Search for values'}
					initialFocusRef={initialFocusRef}
					isSelectable
					eventName={'filters-apply-filter'}
					eventMetadata={eventMetadata}
					searchValuesEventMetadata={{ event: 'filters-search-values', metaData: eventMetadata }}
					selectAllEventMetadata={{ event: 'filters-select-all-values-toggle', metaData: eventMetadata }}
					selectAllMatchingEventMetadata={{ event: 'filters-select-matching-values-toggle', metaData: eventMetadata }}
				/>
			}
		/>
	);
};
