import { Box, Flex } from '@chakra-ui/react';
import BuilderTextInput from 'src/common/components/BuilderTextInput';
import Button from 'src/common/components/Button';
import { AddFilter16, AndText16 } from 'src/common/components/Icons';

import { useCallback, useMemo, useState } from 'react';
import { SuggestionsContextSettings } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { formatBuilderFeatureEditMode } from 'src/pages/MetricPage/utils/eventMetadata';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { EditorFeature } from '../../EditPanel';
import { FiltersTooltip, TooltipIcon } from './BuilderTooltips';
import { LabelWithIcon } from './LabelWithIcon';
import { SelectLabel } from './SelectLabel';
import { useMetricBuilderAIAgent } from '../../../../hooks/useMetricBuilderAIAgent';
import { AskAIFieldSuggestor } from '@components/AskAI/AskAIFieldSuggestor';
import { Filter } from '../../../../utils/editor.types';
import { PendingAISuggestion } from '@components/AskAI/hooks/useAIPendingSuggestions';

function filtersArrayToStrings(filters: Filter[]): string[] {
	return filters.map((filter) => filter.sql);
}

function stringsToFiltersArray(strings?: string[]): Filter[] {
	return strings?.map((filter) => ({ sql: filter })) ?? [];
}

export function AggregateFilters({
	isFieldsDisabled,
	selectedEntity,
	selectedFeature,
	onAddFilter,
}: {
	isFieldsDisabled?: boolean;
	selectedEntity: string;
	selectedFeature: EditorFeature;
	onAddFilter: VoidFunction;
}) {
	const { metricBuilderState, upsertYAMLProperty } = useBuilderDerivedState();
	const { metricNameWithoutFlavor } = useMetricDerivedState();
	const { reportEvent, wrapWithReport } = useReportEvent({
		metricName: metricNameWithoutFlavor,
		feature: 'Metric Builder',
		editMode: formatBuilderFeatureEditMode(selectedFeature),
	});
	const [isAddingNewFilter, setIsAddingNewFilter] = useState(false);

	const isAggregateMetric = metricBuilderState && metricBuilderState.type === 'aggregate';

	const filters = useMemo(() => {
		if (!isAggregateMetric) return [];
		return metricBuilderState.filters?.length ? metricBuilderState.filters : [{ sql: '' }];
	}, [isAggregateMetric, metricBuilderState]);

	const { latestMetricBuilderSuggetion } = useMetricBuilderAIAgent();

	const filtersSuggestion = useMemo((): PendingAISuggestion => {
		return {
			field: 'filters',
			suggestedValue: latestMetricBuilderSuggetion?.suggestedMetric?.filters
				? filtersArrayToStrings(latestMetricBuilderSuggetion?.suggestedMetric?.filters)
				: undefined,
			existingValue: filtersArrayToStrings(filters),
		};
	}, [filters, latestMetricBuilderSuggetion?.suggestedMetric?.filters]);

	const onAcceptFiltersFieldSuggestion = useCallback(
		(values?: string[]) => {
			upsertYAMLProperty('filters', stringsToFiltersArray(values), { shouldPreviewAfter: true });
		},
		[upsertYAMLProperty]
	);

	if (!isAggregateMetric) return null;

	const contextSettings: SuggestionsContextSettings = {
		entity: selectedEntity,
		metric: metricNameWithoutFlavor,
		includeTypes: [
			'dimension',
			'dimension_boolean',
			'function',
			'dimension_date',
			'dimension_numeric',
			'metric',
			'formula_metric',
			'relationship',
			'join',
			'parameter',
			'parameter_store',
		],
	};

	function onAddFilterClicked(e: React.MouseEvent<HTMLButtonElement>) {
		e.stopPropagation();
		e.preventDefault();
		onAddFilter();
	}

	return (
		<>
			<SelectLabel
				text="Filters"
				isOptional
				trailingIcon={
					<TooltipIcon
						tooltipBody={
							<FiltersTooltip
								onHelpClicked={() => {
									reportEvent({
										event: 'metric-edit-help-clicked',
										metaData: {
											component: 'Filters',
										},
									});
								}}
							/>
						}
					/>
				}
				paddingTop={'20px'}
			/>

			<AskAIFieldSuggestor
				suggestion={filtersSuggestion}
				onSuggestionAccepted={onAcceptFiltersFieldSuggestion}
				reportMetadata={{
					metric: metricNameWithoutFlavor,
					metricType: latestMetricBuilderSuggetion?.metricType,
					triggerType: latestMetricBuilderSuggetion?.triggerType,
				}}
			>
				<Flex direction={'column'} gap={'12px'}>
					{filters?.map((filter, index) => (
						<Flex direction="row" alignItems={'center'} gap="8px" key={`filter-input-flex-${index}`}>
							{index > 0 && <AndText16 color={colors.gray[800]} />}
							<BuilderTextInput
								key={index}
								fieldName="filters"
								placeholder={'Add filter'}
								validation={'None'}
								validationText={'Validation text'}
								readOnly={isFieldsDisabled}
								contextSettings={contextSettings}
								rightIcon={
									!filter.sql && (
										<AddFilterViaModalButton
											onAddFilterClicked={wrapWithReport(onAddFilterClicked, 'metric-edit-filters-clicked')}
										/>
									)
								}
								text={filter.sql || ''}
								id={`filter-${index}`}
								onBlur={(value) => {
									const newFilters = filters
										?.map((el, i) => (i === index ? { sql: value } : el))
										.filter((el) => !!el.sql && el.sql !== '');

									upsertYAMLProperty('filters', newFilters.length ? newFilters : undefined, {
										shouldRequestAISuggestions: true,
										shouldPreviewAfter: true,
									});
								}}
							/>
						</Flex>
					))}
				</Flex>
			</AskAIFieldSuggestor>

			{!!filters?.length && !filters.some((el) => el.sql === '') && !isAddingNewFilter && (
				<LabelWithIcon
					text={'Add filter'}
					onClick={() => {
						setIsAddingNewFilter(true);
						reportEvent({
							event: 'metric-edit-filter-added',
							metaData: { feature: 'Metric Builder', entity: selectedEntity, metric: metricNameWithoutFlavor },
						});
					}}
					isFieldsDisabled={isFieldsDisabled}
				/>
			)}
			{isAddingNewFilter && (
				<Flex direction="row" alignItems={'start'} key={`filter-input-flex-add`} gap="8px">
					<Box marginTop={'12px'}>
						<AndText16 color={colors.gray[800]} />
					</Box>
					<BuilderTextInput
						fieldName="filters"
						placeholder={'Add filter'}
						validation={'None'}
						validationText={'Validation text'}
						readOnly={isFieldsDisabled}
						contextSettings={contextSettings}
						initialState="Focus"
						rightIcon={
							<AddFilterViaModalButton
								onAddFilterClicked={wrapWithReport(onAddFilterClicked, 'metric-edit-filters-clicked')}
							/>
						}
						text={''}
						id={'filter-new'}
						onBlur={(value) => {
							setIsAddingNewFilter(false);
							upsertYAMLProperty(
								'filters',
								[...filters, { sql: value }].filter((el) => !!el.sql && el.sql !== ''),
								{ shouldRequestAISuggestions: true, shouldPreviewAfter: true }
							);
						}}
					/>
				</Flex>
			)}
		</>
	);
}

function AddFilterViaModalButton({
	onAddFilterClicked,
}: {
	onAddFilterClicked: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
	return (
		<Button
			data-intercom-area={'metric'}
			data-intercom-type={'button'}
			data-intercom-target={'add-builder-filter'}
			w={'16px'}
			h={'16px'}
			variant="outline"
			size="xxs"
			border={'none'}
			_hover={{ color: colors.gray[800] }}
			_focus={'none'}
			_active={{ color: colors.gray[900] }}
			onMouseDown={onAddFilterClicked}
			isIconOnly
			color={colors.gray[600]}
		>
			<AddFilter16 />
		</Button>
	);
}
