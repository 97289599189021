import Flex from '@components/Flex';
import Box from '@components/Box';
import { SightfullAIBuilderLarge } from '@components/Icons';
import Typography from '@components/Typography';
import { AskAIInput } from 'src/common/components/AskAI/AskAIInput';
import { MetricType } from '../../../common/types/common';
import { useMetricBuilderAIAgent } from '../../MetricPage/hooks/useMetricBuilderAIAgent';
import { useCallback } from 'react';

export function CreateNewMetricWithAIPage({ metricType = 'aggregate' }: { metricType?: MetricType }) {
	const { requestExplicitMetricDefinitionSuggestion, isBusy } = useMetricBuilderAIAgent();

	const onSubmit = useCallback(
		(prompt: string) => {
			requestExplicitMetricDefinitionSuggestion({ prompt, metricType });
		},
		[metricType, requestExplicitMetricDefinitionSuggestion]
	);

	return (
		<Flex
			height={'100%'}
			width={'100%'}
			alignItems={'center'}
			justifyContent={'center'}
			background={
				'radial-gradient(29.66% 50% at 50% 50%, rgba(206, 206, 206, 0.40) 0%, rgba(226, 226, 226, 0.40) 20.5%, rgba(245, 245, 245, 0.40) 100%)'
			}
		>
			<Flex direction={'column'} alignItems={'center'} gap={'4px'} width={'584px'} paddingBottom={'92px'}>
				<SightfullAIBuilderLarge />
				<Box width={'420px'}>
					<Typography variant={'Paragraph16R'} color={'gray.900'} textAlign={'center'}>
						Ask any business question, and Sightfull AI will help you create the metric you need.
					</Typography>
				</Box>
				<Box width={'100%'} marginTop={'15px'}>
					<AskAIInput
						placeholder={'Define the metric you need'}
						autoFocus={true}
						isDisabled={isBusy}
						hasBorder={true}
						borderRadius={'32px'}
						submitButtonBorderRadius={'26px'}
						onSubmit={onSubmit}
						clearInputOnSubmit={false}
						reportEvent={'ai-copilot-main-prompt'}
						reportFeature={'AI Copilot'}
						reportMetadata={{ metricType }}
					/>
				</Box>
			</Flex>
		</Flex>
	);
}
