import {
	Box,
	Input as ChakraInput,
	NumberInput as ChakraNumberInput,
	FormControl,
	FormLabel,
	InputGroup,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInputField,
	NumberInputStepper,
} from '@chakra-ui/react';
import FormErrorMessage from '@components/FormErrorMessage';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import colors from 'src/style/colors';
import { CloseMedium16, Eye16, EyeC16, NumberStepperDown, NumberStepperUp } from '../Icons';
import classes from './Input.module.scss';
import { Left, Right } from './components';
import { getModeStyleProps } from './inputStyles';
import { InputProps, InputType, PasswordState } from './types';

const Input = ({
	label,
	value,
	testId,
	onEnter,
	onChange: onChangeProp,
	size = 'sm',
	placeholder,
	errorMessage,
	type = 'text',
	leftComponent,
	leftComponentPadding = '44px',
	leftComponentLeftPadding = '12px',
	leftComponentBorder = true,
	rightComponent: rightComponentProp,
	rightComponentPadding,
	isInvalid = false,
	isSuccess = false,
	variant = 'normal',
	isDisabled = false,
	isErasable = false,
	eraseIcon,
	rightComponentTestId,
	shouldShowAutocomplete = false,
	color,
	borderRadius,
	borderColor,
	onClick,
	fontSize,
	...props
}: InputProps) => {
	const InputComponent = inputComponents[type];
	const { formControlStyle, inputComponentStyle, borderRightColor } = getModeStyleProps({
		disabled: isDisabled,
		error: isInvalid,
		success: isSuccess,
	});

	const inputRef = useRef<HTMLInputElement>(null);

	const className = isDisabled ? 'disabled' : isInvalid ? 'error' : 'enabled';
	const leftComponentBorderRightColor = borderRightColor ?? colors.gray['400'];

	const [passwordIcon, setPasswordIcon] = useState<PasswordState>('hidePassword');
	const [innerType, setInnerType] = useState<InputType>();

	const onChange = useCallback(
		(value: string) => {
			onChangeProp?.(value);
		},
		[onChangeProp]
	);

	useEffect(() => {
		const innerType = type != 'password' ? 'text' : 'password';
		setInnerType(innerType);
	}, [type]);

	const togglePasswordState = useCallback(() => {
		setInnerType(passwordIcon == 'hidePassword' ? 'text' : 'password');
		setPasswordIcon((prev) => (prev == 'hidePassword' ? 'showPassword' : 'hidePassword'));
	}, [passwordIcon]);

	const clearText = useCallback(() => {
		if (isDisabled) {
			return;
		}
		onChange('');
		inputRef?.current?.focus();
	}, [isDisabled, onChange]);

	const rightComponent = useMemo((): ReactNode => {
		if (type == 'password') {
			return <Box onClick={togglePasswordState}>{PasswordRightIcons[passwordIcon]}</Box>;
		}

		if (isErasable) {
			return <button onClick={clearText}>{eraseIcon ?? <CloseMedium16 />}</button>;
		}

		return rightComponentProp ?? null;
	}, [type, isErasable, rightComponentProp, togglePasswordState, passwordIcon, eraseIcon, clearText]);

	return (
		<FormControl isInvalid={isInvalid} {...formControlStyle}>
			{label && <FormLabel>{label}</FormLabel>}
			<InputGroup size={size} className={classes[className]} gap="30px">
				{leftComponent && (
					<Left
						component={leftComponent}
						leftComponentLeftPadding={leftComponentLeftPadding}
						borderRightColor={leftComponentBorder ? leftComponentBorderRightColor : undefined}
					/>
				)}
				<InputComponent
					{...inputComponentStyle}
					color={color}
					borderRadius={borderRadius}
					borderColor={borderColor}
					_ref={inputRef}
					testId={testId}
					size={size}
					value={value}
					type={innerType}
					variant={variant}
					fontSize={fontSize}
					onChange={onChange}
					isDisabled={isDisabled}
					placeholder={placeholder}
					pl={leftComponent ? leftComponentPadding : undefined}
					pr={rightComponent ? '40px' : undefined}
					autoComplete={shouldShowAutocomplete ? 'on' : 'off'}
					onKeyDown={(e) => e.key == 'Enter' && onEnter?.(String(value))}
					onClick={onClick}
					{...props}
				/>
				{rightComponent && (
					<Right
						component={rightComponent}
						testId={rightComponentTestId}
						onClick={rightComponentProp && onClick}
						rightComponentPadding={rightComponentPadding}
					/>
				)}
			</InputGroup>
			{errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
		</FormControl>
	);
};

export default Input;

const NumberInput = ({
	size,
	variant,
	width,
	height,
	value,
	onChange,
	onBlur,
	isDisabled,
	chakraNumberInputProps,
	testId,
	hasNumberSteppers,
	backgroundColor,
	...inputAreaProps
}: InputProps) => {
	const [currentValue, setCurrentValue] = useState<string | undefined>(value ? value.toString() : undefined);

	const handleChange = useCallback(
		(newVal: string) => {
			const newValAsNumber = parseFloat(newVal);
			if (!isNaN(newValAsNumber)) {
				onChange?.(newValAsNumber.toString());
			}
			setCurrentValue(newVal);
		},
		[onChange]
	);

	const handleBlur = useCallback(() => {
		if (currentValue == undefined) {
			return;
		}
		if (currentValue == '') {
			setCurrentValue((chakraNumberInputProps?.min ?? 0).toString());
		}
		onChange?.(currentValue.toString());
	}, [chakraNumberInputProps?.min, currentValue, onChange]);

	useEffect(() => {
		if (value == undefined) {
			return;
		}
		setCurrentValue(value.toString());
	}, [value]);

	return (
		<ChakraNumberInput
			{...chakraNumberInputProps}
			variant={variant}
			size={size}
			width={width}
			height={height}
			value={currentValue}
			onChange={handleChange}
			onBlur={onBlur ?? handleBlur}
			isDisabled={isDisabled}
		>
			<NumberInputField data-testid={testId} {...inputAreaProps} backgroundColor={backgroundColor} />
			{hasNumberSteppers && (
				<NumberInputStepper>
					<NumberIncrementStepper
						color={'gray.800'}
						_hover={{ color: 'black' }}
						width={'16px'}
						height={'8px'}
						marginTop={'6px'}
						borderColor={'transparent'}
						_disabled={{ cursor: 'default', color: 'gray.500' }}
					>
						<NumberStepperUp />
					</NumberIncrementStepper>
					<NumberDecrementStepper
						color={'gray.800'}
						_hover={{ color: 'black' }}
						width={'16px'}
						height={'8px'}
						marginBottom={'6px'}
						borderColor={'transparent'}
						_disabled={{ cursor: 'default', color: 'gray.500' }}
					>
						<NumberStepperDown />
					</NumberDecrementStepper>
				</NumberInputStepper>
			)}
		</ChakraNumberInput>
	);
};

const TextInputWrapper = ({ onChange, testId, _ref, ...props }: InputProps) => {
	const borderColorStyle = props.borderColor && { borderColor: props.borderColor };

	return (
		<ChakraInput
			_hover={borderColorStyle}
			_focus={borderColorStyle}
			ref={_ref}
			data-testid={testId}
			onChange={(e) => onChange?.(e.target.value)}
			{...props}
		/>
	);
};

const inputComponents: { [key in InputType]: ({ onChange, ...props }: InputProps) => JSX.Element } = {
	text: TextInputWrapper,
	number: NumberInput,
	password: TextInputWrapper,
};

const PasswordRightIcons: { [key in PasswordState]: JSX.Element } = {
	hidePassword: <Eye16 />,
	showPassword: <EyeC16 />,
};
