import { ValueFormatterParams } from 'ag-grid-community';
import { formatValue } from 'src/common/utils/valueFormatting';
import { ColDef } from 'src/generated/graphql';
import TenantConfigType from 'src/types/tenantConfig';
import {
	ConnectorLogoRenderer,
	CreatedByRenderer,
	StatusBadgeRenderer,
	ActionsRenderer,
} from '../components/ConnectorsTable/renderers';
import { CellRenderer } from 'src/common/components/Table/CellRenderer';
import { NOT_ACTIVE_COLUMNS } from './constants';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { formatDate, formatDateTime } from 'src/common/utils/valueFormatting/formatters';
import { NameCol } from './types';

export const getCellRenderer = (colName: string) => {
	switch (colName) {
		case '$name':
			return ConnectorLogoRenderer();
		case '$created':
			return CreatedByRenderer();
		case '$status':
			return StatusBadgeRenderer();
		case '$actions':
			return ActionsRenderer();
		default:
			return CellRenderer(false);
	}
};

export const serializeConnectorsTableRowDef = (rows: Record<string, any>[]) => {
	if (!rows.length) return [];
	return rows.map((row, index) => ({
		_row_id: index,
		$name: {
			sourceName: capitalizedFirstLetter(row.name),
			status: row.connectorStatus,
			type: capitalizedFirstLetter(row.type),
		},
		$last_sync: row.lastSuccessfulSync && formatDateTime(row.lastSuccessfulSync),
		$created: {
			date: row.createdAt && formatDate(row.createdAt),
			author: row.createdBy,
		},
		$status: row.connectorStatus,
	}));
};

export const serializeConnectorsTableColumnDef = (colDefs: ColDef[], tenantConfig: TenantConfigType) =>
	colDefs.map((col) => ({
		headerName: col?.colDisplayName,
		field: col?.colName,
		pinned: false,
		valueFormatter: (params: ValueFormatterParams) => formatValue(col.colName, params.value, tenantConfig),
		filterKey: col?.colName,
		resizable: false,
		cellRenderer: getCellRenderer(col?.colName),
		headerComponentParams: { removable: false, isWithColMenu: false, columnsWithoutActions: NOT_ACTIVE_COLUMNS },
		comparator: (valueA: string | NameCol, valueB: string | NameCol) => {
			if (
				col?.colName === '$name' &&
				typeof valueA === 'object' &&
				'sourceId' in valueA &&
				typeof valueB === 'object' &&
				'sourceId' in valueB
			)
				return valueA.sourceId.localeCompare(valueB.sourceId);
			if (typeof valueA === 'string' && typeof valueB === 'string') return valueA.localeCompare(valueB);
			if (valueA === valueB) return 0;
			return valueA > valueB ? 1 : -1;
		},
	}));
