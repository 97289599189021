import Flex from '@components/Flex';
import Button from 'src/common/components/Button';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useUserRequestSetupConnectorLazyQuery } from 'src/generated/graphql';
import { DataConnectorsModal } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsModal';
import { DataConnectorsResponseModal } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsResponseModal';
import { AICPA44, ISO44, Key24B, Office16B, Plus16, ShieldCheck16B } from 'src/common/components/Icons';
import { HttpStatusCode } from 'axios';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import classes from './ConnectorsBanner.module.scss';
import { useReportEvent } from 'src/services/analytics';

export const ConnectorsBanner = () => {
	const {
		isOpen: isDataConnectorsModalOpen,
		onOpen: onDataConnectorsModalOpen,
		onClose: onDataConnectorsModalClose,
	} = useModal();
	const {
		isOpen: isDataConnectorsResponseModalOpen,
		onOpen: onDataConnectorsResponseModalOpen,
		onClose: onDataConnectorsResponseModalClose,
	} = useModal();

	const { bannerWrapper, contentWrapper, contentDescription, contentList } = classes;

	const { reportEvent } = useReportEvent();

	const [requestSetupConnector, { data: requestSetupConnectorResponse, loading: requestSetupConnectorLoading }] =
		useUserRequestSetupConnectorLazyQuery({ fetchPolicy: 'no-cache' });

	return (
		<Flex className={bannerWrapper}>
			<Flex className={contentWrapper}>
				<Typography marginBottom={'12px'} color={'gray.1000'} variant={'DesktopH3M'} textAlign="start">
					Connect your data source with confidence.
				</Typography>
				<Typography className={contentDescription} variant={'DesktopH8Regular'}>
					Connect your data from hundreds of integrations to keep your metrics, and dashboards always up-to-date. Your
					data’s security is our top priority.
				</Typography>
				<Flex className={contentList}>
					<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
						<Key24B color={colors.gray['900']} />
						<Typography variant={'DesktopH8Medium'} color={colors.gray['900']} textAlign={'center'}>
							Data encryption
						</Typography>
					</Flex>
					<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
						<Office16B color={colors.gray['900']} />
						<Typography variant={'DesktopH8Medium'} color={colors.gray['900']} textAlign={'center'}>
							Comply with industry standards
						</Typography>
					</Flex>
					<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
						<ShieldCheck16B color={colors.gray['900']} />
						<Typography variant={'DesktopH8Medium'} color={colors.gray['900']} textAlign={'center'}>
							Strict access control
						</Typography>
					</Flex>
				</Flex>

				<Flex alignItems={'center'} width={'100%'} justify={'left'} gap={'24px'}>
					<div
						data-intercom-area={'main'}
						data-intercom-type={'main'}
						data-intercom-target={`data-connectors-modal-button`}
					>
						<Button
							borderRadius={'8px'}
							variant="solid"
							onClick={() => {
								reportEvent({
									event: 'connectors-add-connector',
								});
								onDataConnectorsModalOpen();
							}}
							leftIcon={<Plus16 />}
							size="small"
							colorScheme="blue"
						>
							Add new datasource
						</Button>
					</div>
					<Flex alignItems={'center'} gap={'10px'}>
						<ISO44 />
						<AICPA44 />
					</Flex>
				</Flex>
			</Flex>
			<DataConnectorsModal
				isOpen={isDataConnectorsModalOpen}
				onClose={onDataConnectorsModalClose}
				requestSetupConnectorLazyQuery={requestSetupConnector}
				onNextButtonClick={onDataConnectorsResponseModalOpen}
			/>
			<DataConnectorsResponseModal
				isOpen={isDataConnectorsResponseModalOpen}
				onClose={onDataConnectorsResponseModalClose}
				isSuccess={requestSetupConnectorResponse?.userRequestSetupConnector.status == HttpStatusCode.Ok.toString()}
				isLoading={requestSetupConnectorLoading}
			/>
		</Flex>
	);
};
