import colors from 'src/style/colors';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import Image from '@components/Image';
import Tooltip from '@components/Tooltip';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';

export function DataConnectorCard({
	icon,
	name,
	onClick,
	isSelected,
}: Pick<DataConnectorInfo, 'icon' | 'name'> & {
	onClick: () => void;
	isSelected: boolean;
}) {
	return (
		<Flex
			maxWidth={'169.5px'}
			width={'169.5px'}
			border={'2px solid '}
			borderColor={isSelected ? colors.gray['900'] : 'transparent'}
			borderRadius={'12px'}
		>
			<Flex
				width={'100%'}
				padding={'11px 16px'}
				borderRadius={'12px'}
				border={`1px solid`}
				borderColor={isSelected ? 'transparent' : colors.gray['300']}
				onClick={onClick}
				_hover={{ borderColor: !isSelected && colors.gray['500'], cursor: 'pointer' }}
				transitionProperty="border-color"
				transitionDuration={isSelected ? '250ms' : ''}
				userSelect={'none'}
			>
				<Flex gap={'10px'} width={'100%'} alignItems={'center'}>
					<Image
						onError={(e) => {
							if (e.target instanceof HTMLImageElement) e.target.src = PLACEHOLDER_ICON;
						}}
						src={icon}
						maxWidth={'24px'}
						height={'24px'}
						width={'100%'}
					/>

					<Tooltip size={'sm'} label={name}>
						<Typography
							noOfLines={2}
							variant={'DesktopH10Medium'}
							textAlign={'start'}
							color={colors.gray['1000']}
							maxWidth={'105px'}
						>
							{name}
						</Typography>
					</Tooltip>
				</Flex>
			</Flex>
		</Flex>
	);
}
