import Box from '@components/Box';
import Flex from '@components/Flex';
import Skeleton from '@components/Skeleton';
import { useMetricDerivedState } from '../../../../../pages/MetricPage/hooks/useMetricDerivedState';

export type EditableValueContainerProps = {
	prefixComponent?: React.ReactNode;
	children: React.ReactNode;
};

export function EditableValueContainer({ prefixComponent, children }: EditableValueContainerProps) {
	const { isLoading: isRulesEngineLoading } = useMetricDerivedState();

	return (
		<Flex color={'gray.1000'} pl={'26px'} alignItems={'center'}>
			{prefixComponent}
			<Box flex={1} overflowX={'hidden'}>
				{isRulesEngineLoading ? <Skeleton height={'32px'} /> : children}
			</Box>
		</Flex>
	);
}
