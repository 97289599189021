import Box from '@components/Box';
import Typography from '@components/Typography';
import Divider from '@components/Divider';
import { FilterOutline16 } from '@components/Icons';
import { FilterDrilldownCard } from '@components/LeftExpandCollapsePanel/Drilldown';
import { AddDrillDownCard } from '@components/LeftExpandCollapsePanel/Drilldown/AddDrillDownCard';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useReportEvent } from '@services/analytics';
import useDimensionsState from '../InvestigatePanel/useDimensionsState';
import { OnModalCloseType, OnModalOpenType } from './types';
import { Title } from '../PanelTitle/Title';
import * as CONSTS from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import classes from '../InvestigatePanel/InvestigatePanel.module.scss';
import { MetricPagePath } from '@pages/MetricPage/pageRoutesPaths';

export function FiltersPanel({
	isShowingFullSize,
	isDisabled,
	onModalOpen,
	onModalClose,
}: {
	isShowingFullSize: boolean;
	isDisabled?: boolean;
	onModalOpen: OnModalOpenType;
	onModalClose: OnModalCloseType;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const [{ filters }, { removeAllParamsByType, removeParamByKey }] = useDimensionsState();
	const { wrapWithReport, reportEvent } = useReportEvent();
	const isAddFilterButtonShown = !isDisabled && filters.every((filter) => filter.values.length > 0);
	const { pathname } = window.location;
	const isMetricPage = pathname.includes(`/${MetricPagePath}/`);
	const eventMetadata = {
		feature: 'filters',
		page: isMetricPage ? 'Metric Page' : 'Signal Page',
		application: 'metric investigation',
	};

	return (
		<>
			<Box whiteSpace="nowrap" height={isShowingFullSize ? 'min-content' : '0'} opacity={isShowingFullSize ? '1' : '0'}>
				<Title
					onAction={() => {
						reportEvent({ event: 'filters-remove-all-filters', metaData: eventMetadata });
						removeAllParamsByType('filterBy');
					}}
					text={'Filters'}
					showAction={!!filters?.length}
				/>
				{!isDisabled &&
					filters.map((filter) => {
						const isEditorAutoOpened = filter.values.length === 0 && isShowingFullSize;

						return (
							<FilterDrilldownCard
								key={filter.key + filter.values.join('')}
								filter={filter}
								onRemove={wrapWithReport(
									() => removeParamByKey('filterBy', filter.key),
									'metric-filters-delete-clicked'
								)}
								onClick={
									isSightfull2
										? () => undefined
										: wrapWithReport(
												() => onModalOpen(CONSTS.FILTER_MODEL_TYPE, onModalClose, filter),
												'edit-metric-filters-clicked'
										  )
								}
								isEditorAutoOpened={isEditorAutoOpened}
								eventMetadata={eventMetadata}
							/>
						);
					})}
				{isAddFilterButtonShown && (
					<AddDrillDownCard
						isDisabled={isDisabled}
						type={CONSTS.FILTER_MODEL_TYPE}
						onClick={wrapWithReport(
							() => onModalOpen(CONSTS.FILTER_MODEL_TYPE, onModalClose),
							'metric-filters-clicked'
						)}
						dataIntercomTarget={'Add filter'}
					/>
				)}
			</Box>
			{!isShowingFullSize && (
				<Box
					display="flex"
					width="32px"
					height="32px"
					paddingTop={'15px'}
					position="relative"
					justifyContent="center"
					alignItems="center"
				>
					<FilterOutline16 />
					{!!filters.length && (
						<Box className={classes.badgeNumber}>
							<Typography variant="Paragraph12M">{filters.length}</Typography>
						</Box>
					)}
				</Box>
			)}
			<Box paddingY={isShowingFullSize ? '24px' : '8px'}>
				<Divider direction={'horizontal'}></Divider>
			</Box>
		</>
	);
}
