import { AskSightfullAI } from '@components/TopNavigation/AskSightfullAI';
import { DataConnectorsModal } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsModal';
import { DataConnectorsResponseModal } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsResponseModal';
import { AdminPortal } from '@frontegg/react';
import { HttpStatusCode } from 'axios';
import classnames from 'classnames';
import React, { useState } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useUser from 'src/common/hooks/stores/useUser';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useSemanticsStatus } from 'src/common/hooks/useSemanticsStatus';
import { keyByOperatingSystem } from 'src/common/utils/utils';
import { useUserRequestSetupConnectorLazyQuery } from 'src/generated/graphql';
import NavigationAvatar from 'src/layout/Menu/Navigation/NavigationAvatar';
import NavigationIcon from 'src/layout/Menu/Navigation/NavigationIcon';
import { SearchBoxV1, SearchBoxV2 } from 'src/layout/Menu/SearchBox/SearchBox';
import TasksStatus from 'src/layout/Menu/TasksStatus/TasksStatus';
import { ConnectorsPagePath } from 'src/pages/ConnectorsPage';
import { OntologyPagePath } from 'src/pages/OntologyPage/OntologyBuilderPage';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { useEnvironment, usePermissionCheck } from 'src/stores/environment';
import { useTenantConfigStore } from 'src/stores/tenantConfig';
import { Permissions } from 'src/types/environment';
import { AskAIModal } from '../AskAI';
import Avatar from '../Avatar/Avatar';
import Box from '../Box';
import Center from '../Center';
import Flex from '../Flex';
import {
	AskAIRounded20,
	AskSightfullAILogo26,
	Database16,
	Ellipse14,
	K20,
	Logo28,
	Ontology20,
	Search36,
	SightfullLogo,
	User20,
} from '../Icons';
import Link from '../Link';
import { NavigationTabBar } from './NavigationTabBar';
import classes from './TopNavigation.module.scss';

const spotlightIcon = (
	<Center height={'36px'} width={'36px'} color={'gray.1000'} className={classes.navigationIcon}>
		<Search36 />
	</Center>
);

const activeSpotlightIcon = (
	<Center
		height={'36px'}
		width={'36px'}
		color={'gray.1000'}
		className={classnames(classes.navigationIcon, classes.activeIcon)}
	>
		<Search36 />
	</Center>
);

const ontologyIcon = (
	<Center height={'36px'} width={'36px'} color={'gray.1000'} className={classes.navigationIcon}>
		<Ontology20 />
	</Center>
);

const inviteUserIcon = (
	<Center height={'36px'} width={'36px'} color={'gray.1000'} className={classes.navigationIcon}>
		<User20 />
	</Center>
);

const activeOntologyIcon = (
	<Center
		height={'36px'}
		width={'36px'}
		color={'gray.1000'}
		className={classnames(classes.navigationIcon, classes.activeIcon)}
	>
		<Ontology20 />
	</Center>
);

function DataConnectorsIcon({ isActive }: { isActive: boolean }) {
	return (
		<Center
			height={'36px'}
			width={'36px'}
			color={'gray.1000'}
			className={isActive ? classnames(classes.navigationIcon, classes.activeIcon) : classes.navigationIcon}
		>
			<Database16 />
		</Center>
	);
}

export const TopNavigation = () => {
	const {
		isOpen: isDataConnectorsModalOpen,
		onOpen: onDataConnectorsModalOpen,
		onClose: onDataConnectorsModalClose,
	} = useModal();
	const {
		isOpen: isDataConnectorsResponseModalOpen,
		onOpen: onDataConnectorsResponseModalOpen,
		onClose: onDataConnectorsResponseModalClose,
	} = useModal();
	const { wrapWithReport, reportEvent } = useReportEvent();
	const { navigate, getHref } = useNavigation();
	const { isOpen, onOpen, onClose } = useModal();
	const { isOpen: isDiscoveryOpen, onOpen: onDiscoveryOpen, onClose: onDiscoveryClose } = useModal();
	const [requestSetupConnector, { data: requestSetupConnectorResponse, loading: requestSetupConnectorLoading }] =
		useUserRequestSetupConnectorLazyQuery({ fetchPolicy: 'no-cache' });

	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isDataConnectorsModalEnabled = useFeatureFlag('pulse.sightfull2.dataConnectorsModal.enable');
	const isDataConnectorsPageEnabled = useFeatureFlag('pulse.sightfull2.dataConnectorsPage.enable');

	const isDataOverridesEnabled = useFeatureFlag('pulse.node.overrideEnabled') && !isSightfull2;
	const isOntologyPageEnabled = isSightfull2;
	const hasEntitiesPermission = usePermissionCheck().isHavingOneOfPermissions([
		Permissions.readEntities,
		Permissions.writeEntities,
	]);
	const [{ picture, firstName, lastName }] = useUser();
	const { logoUrl } = useTenantConfigStore();
	const { isMultiTenant } = useEnvironment();
	const fullName = `${firstName} ${lastName}`;

	const [{ metricsFullyDefined, ontologyFullyDefined, isSemanticsStatusLoading }] = useSemanticsStatus(true);
	const [isTopNavigationMenuOpen, setIsTopNavigationMenuOpen] = useState(false);
	const isDiscoveryEnabled = useFeatureFlag('pulse.discovery.enable') && isSightfull2;
	const isAccountSettingsEnabled = useFeatureFlag('pulse.settings.enable');

	const MissingOntologyDot = () => (
		<Box position={'absolute'} alignSelf={'flex-end'}>
			<Box position={'relative'} marginTop={'4px'} marginRight={'4px'} color={'yellow.700'}>
				<Ellipse14 />
			</Box>
		</Box>
	);

	return (
		<Flex
			width="100%"
			alignItems="space-between"
			padding="12px 32px"
			maxH="60px"
			className={classes.container}
			gap={'35px'}
		>
			<Flex alignItems={'center'} gap={'24px'} flex={'1'}>
				<Link href={getHref({ path: '' })}>
					<Box
						onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							navigate({
								path: '/',
							});
							reportEvent({ event: 'top-navigation-logo-clicked', metaData: { feature: 'Main Navigation Bar' } });
						}}
					>
						<Logo28 className={classes.smallLogo} />
						<SightfullLogo className={classes.bigLogo} />
					</Box>
				</Link>
				<NavigationTabBar
					isSemanticsStatusLoading={isSemanticsStatusLoading ?? true}
					metricsFullyDefined={metricsFullyDefined ?? true}
				/>
			</Flex>
			{isSightfull2 && (
				<Box className={classes.askSightfullAIWrapper} onClick={onDiscoveryOpen}>
					<AskSightfullAI enabled={isDiscoveryEnabled} />
				</Box>
			)}
			<Flex h="38px" alignItems="center" justifyContent={'flex-end'} flex={'1'}>
				<Flex gap={'4px'} alignItems={'center'}>
					{isDataOverridesEnabled && <TasksStatus />}
					{isSightfull2 && (
						<Center height={'36px'} width={'36px'} className={classes.askSightfullAIForSmallScreens}>
							{isDiscoveryEnabled ? (
								<Link onClick={onDiscoveryOpen}>
									<AskAIRounded20 />
								</Link>
							) : (
								<AskSightfullAILogo26 />
							)}
						</Center>
					)}
					{isOntologyPageEnabled && hasEntitiesPermission && (
						<Link href={getHref({ path: 'ontology' })}>
							<Flex direction={'column'}>
								<NavigationIcon
									tooltipLabel={<Flex alignItems={'center'}>Ontology</Flex>}
									padding="0"
									icon={ontologyIcon}
									activeIcon={activeOntologyIcon}
									overrideOnClick={wrapWithReport(
										() =>
											navigate({
												path: `/${OntologyPagePath}`,
											}),
										'top-navigation-icon-clicked',
										{ item: 'ontology', feature: 'Main Navigation Bar', isPendingState: !ontologyFullyDefined }
									)}
									isActiveOverride={window.location.toString().includes(OntologyPagePath)}
									dataIntercomArea={'topbar'}
									dataIntercomType={'button'}
									dataIntercomTarget={'Ontology'}
									hasExternalIconStyles={true}
								/>
								{!isSemanticsStatusLoading && !(ontologyFullyDefined ?? true) && <MissingOntologyDot />}
							</Flex>
						</Link>
					)}
					{!isDiscoveryEnabled && (
						<Box mr="8px">
							<NavigationIcon
								tooltipLabel={
									<Flex alignItems={'center'}>
										Search
										<Box as={'div'} pr={'4px'} pl={'12px'}>
											{keyByOperatingSystem}
										</Box>
										<K20 />
									</Flex>
								}
								padding="0"
								icon={spotlightIcon}
								activeIcon={activeSpotlightIcon}
								overrideOnClick={wrapWithReport(() => onOpen(), 'top-navigation-icon-clicked', {
									item: 'search',
									feature: 'Main Navigation Bar',
								})}
								isActiveOverride={isOpen}
								dataIntercomArea={'topbar'}
								dataIntercomType={'button'}
								dataIntercomTarget={'Search'}
								hasExternalIconStyles={true}
							/>
						</Box>
					)}
					{isSightfull2 &&
						(isDataConnectorsModalEnabled || isDataConnectorsPageEnabled) &&
						(isDataConnectorsPageEnabled ? (
							<NavigationIcon
								dataIntercomArea={'topbar'}
								dataIntercomType={'button'}
								dataIntercomTarget={'Connectors'}
								isActiveOverride={window.location.toString().includes(ConnectorsPagePath)}
								hasExternalIconStyles={true}
								padding="0"
								icon={<DataConnectorsIcon isActive={false} />}
								activeIcon={<DataConnectorsIcon isActive={true} />}
								tooltipLabel={<Flex alignItems={'center'}>Data connectors</Flex>}
								overrideOnClick={() => {
									navigate({
										path: `/${ConnectorsPagePath}`,
									});
									reportEvent({
										event: 'top-navigation-icon-clicked',
										metaData: {
											item: 'connectors',
											feature: 'Main Navigation Bar',
											page: 'Menu',
										},
									});
								}}
							/>
						) : (
							<>
								<DataConnectorsModal
									isOpen={isDataConnectorsModalOpen}
									onClose={onDataConnectorsModalClose}
									requestSetupConnectorLazyQuery={requestSetupConnector}
									onNextButtonClick={onDataConnectorsResponseModalOpen}
								/>
								<DataConnectorsResponseModal
									isOpen={isDataConnectorsResponseModalOpen}
									onClose={onDataConnectorsResponseModalClose}
									isSuccess={
										requestSetupConnectorResponse?.userRequestSetupConnector.status == HttpStatusCode.Ok.toString()
									}
									isLoading={requestSetupConnectorLoading}
								/>
								<div
									data-intercom-area={'main'}
									data-intercom-type={'main'}
									data-intercom-target={`data-connectors-modal-button`}
								>
									<NavigationIcon
										isActiveOverride={isDataConnectorsModalOpen}
										hasExternalIconStyles={true}
										padding="0"
										icon={<DataConnectorsIcon isActive={false} />}
										activeIcon={<DataConnectorsIcon isActive={true} />}
										tooltipLabel={<Flex alignItems={'center'}>Data connectors</Flex>}
										overrideOnClick={() => {
											onDataConnectorsModalOpen();
											reportEvent({
												event: 'top-navigation-icon-clicked',
												metaData: {
													item: 'connectors',
													feature: 'Main Navigation Bar',
													page: 'Menu',
												},
											});
										}}
									/>
								</div>
							</>
						))}
					<Link href={getHref({ path: 'ontology' })}>
						<Flex direction={'column'}>
							{isAccountSettingsEnabled && (
								<NavigationIcon
									tooltipLabel={<Flex alignItems={'center'}>Invite users</Flex>}
									padding="0"
									icon={inviteUserIcon}
									overrideOnClick={wrapWithReport(
										() => {
											window.location.hash = '#/admin-box/users';
											AdminPortal.show();
										},
										'top-navigation-icon-clicked',
										{ item: 'invite-users', feature: 'Main Navigation Bar' }
									)}
									dataIntercomArea={'topbar'}
									dataIntercomType={'button'}
									dataIntercomTarget={'Invite'}
									hasExternalIconStyles={true}
								/>
							)}
						</Flex>
					</Link>
					<Box position={'relative'}>
						<NavigationAvatar
							icon={
								<Avatar
									width="32px"
									height="32px"
									shape="round"
									imageUrl={isMultiTenant ? logoUrl : picture}
									name={fullName}
									isTopNavigationAvatar={true}
									isActive={isTopNavigationMenuOpen}
								/>
							}
							isTopNavigationMenuOpen={isTopNavigationMenuOpen}
							setIsTopNavigationMenuOpen={setIsTopNavigationMenuOpen}
						/>
					</Box>
				</Flex>
			</Flex>
			{isDiscoveryEnabled ? (
				<AskAIModal isOpen={isDiscoveryOpen} onClose={onDiscoveryClose} onOpen={onDiscoveryOpen} />
			) : isSightfull2 ? (
				<SearchBoxV2 isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
			) : (
				<SearchBoxV1 isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
			)}
		</Flex>
	);
};
