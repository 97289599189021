import Box from '@components/Box';
import { ArrowUp16, AskAIGray24, BouncingThreeDots, CRemove16 } from '@components/Icons';
import Input from '@components/Input';
import { InputProps } from '@components/Input/types';
import ResizableIcon from '@components/ResizableIcon';
import { useCallback, useMemo, useState } from 'react';
import Button from '../Button';
import classes from './AskAI.module.scss';
import { useAskAIBusyState } from './hooks/useAskAIQueryRunState';
import { useReportAIEvent } from './hooks/useReportAIEvent';

type AskAIInputProps = InputProps & {
	placeholder: string;
	value?: string;
	hasBorder: boolean;
	onSubmit?: (value: string) => void;
	testId?: string;
	isErasable?: boolean;
	borderRadius?: string;
	submitButtonBorderRadius?: string;
	clearInputOnSubmit?: boolean;
	autoFocus?: boolean;
	reportEvent: string;
	reportFeature: string;
	reportMetadata?: Record<string, any>;
};

function SubmitButton({
	disabled,
	busy,
	onSubmit,
	borderRadius,
	size,
}: {
	disabled: boolean;
	busy: boolean;
	onSubmit: () => void;
	borderRadius?: string;
	size?: string;
}) {
	return (
		<Button
			variant={'solid'}
			size={size === 'md' ? 'xs' : 'xxs'}
			isIconOnly={true}
			colorScheme={'black'}
			border={'none'}
			isDisabled={disabled}
			onClick={onSubmit}
			borderRadius={borderRadius}
		>
			{busy ? <ResizableIcon size={'16px'} icon={BouncingThreeDots} /> : <ArrowUp16 />}
		</Button>
	);
}

export function AskAIInput({
	placeholder,
	isDisabled,
	onSubmit,
	onChange,
	isErasable,
	hasBorder,
	value = '',
	borderRadius = '8px',
	size = 'md',
	submitButtonBorderRadius,
	clearInputOnSubmit = true,
	autoFocus = true,
	reportEvent,
	reportFeature,
	reportMetadata = {},
}: AskAIInputProps) {
	const { reportAIEvent } = useReportAIEvent();
	const [inputValue, setInputValue] = useState(value);
	const [busy] = useAskAIBusyState();
	const isSubmitDisabled = useMemo(() => !inputValue || busy, [busy, inputValue]);

	const onInnerChange = useCallback(
		(value: string) => {
			setInputValue(value);
			onChange?.(value);
		},
		[onChange]
	);

	const onEnter = useCallback(
		(userPrompt: string) => {
			if (isSubmitDisabled || userPrompt.trim() == '') return;
			if (clearInputOnSubmit) setInputValue('');
			reportAIEvent({
				event: reportEvent,
				feature: reportFeature,
				metaData: {
					...reportMetadata,
					userPrompt,
				},
			});
			onSubmit?.(userPrompt);
		},
		[clearInputOnSubmit, isSubmitDisabled, onSubmit, reportAIEvent, reportEvent, reportFeature, reportMetadata]
	);

	const onClickSubmit = useCallback(() => {
		onEnter(inputValue);
	}, [inputValue, onEnter]);

	return (
		<Box className={hasBorder ? classes['ask-ai-input'] : ''} borderRadius={borderRadius} flex={1}>
			<Input
				borderRadius={borderRadius}
				placeholder={placeholder}
				isDisabled={isDisabled}
				color={'gray.1100'}
				size={size}
				shouldShowAutocomplete={false}
				onEnter={onEnter}
				isErasable={isErasable && !!inputValue}
				eraseIcon={<CRemove16 />}
				variant={'ghost'}
				leftComponent={<AskAIGray24 />}
				leftComponentPadding={size === 'md' ? '42px' : '38px'}
				leftComponentLeftPadding={size === 'md' ? '12px' : '8px'}
				leftComponentBorder={false}
				rightComponent={
					!isErasable ? (
						<SubmitButton
							disabled={isSubmitDisabled}
							size={size}
							busy={busy}
							onSubmit={onClickSubmit}
							borderRadius={submitButtonBorderRadius}
						/>
					) : undefined
				}
				rightComponentPadding={size === 'md' ? '10px' : '8px'}
				onChange={onInnerChange}
				value={inputValue}
				testId={`ask-ai-input`}
				autoFocus={autoFocus}
			/>
		</Box>
	);
}
