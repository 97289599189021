import Flex from '@components/Flex';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { Provider } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditorLoader } from 'src/common/EditorLoader';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import { TagIcon24 } from 'src/common/components/Icons';
import InteractiveMetricChat from 'src/common/components/MetricView/InteractiveMetricChat';
import MetricViewLayout from 'src/common/components/MetricView/MetricViewLayout';
import Typography from 'src/common/components/Typography';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { TestIDs } from 'src/common/types/test-ids';
import { UpsertMetricMutation, UpsertMetricMutationVariables } from 'src/generated/graphql';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useMetricMeta } from 'src/lib/metricRules/builder/useCachedBuilderProperties';
import { isUntitledMetric, useMetricBuilder } from 'src/lib/metricRules/builder/useMetricBuilder';
import { removeEscaping, singleMetricToFullYaml } from 'src/models/YamlUtils/yamlUtils';
import { useUpdatedPreviewHashState } from 'src/pages/MetricPage/hooks/useUpdatedPreviewHashAtom';
import { UpsertMetric } from 'src/queries/core-manager';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import useNavigation from 'src/services/useNavigation';
import useNavigationBlock from 'src/services/useNavigationBlock';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import LeftExpandCollapsePanel from '../../common/components/LeftExpandCollapsePanel';
import ResponsiveHeader from '../Spaces/common';
import { getInitialDerivedStateAtom } from './atoms/DerivedState';
import { IsMetricPageURLBasedAtom, useMetricPageSearchParamsSource } from './atoms/MetricPageSearchParams';
import { EditorFeature } from './components/CalculatePanel/EditPanel';
import { DrillDownPanel } from './components/DrillDownPanel';
import FiltersAndBreakdownsModal from './components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import { useCoreNodeScheme } from './components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useNodeScheme } from './components/FiltersAndBreakdown/NodeScheme/useNodeScheme';
import { OnModalOpenType } from './components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown from './components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { Header } from './components/Header/Header';
import { LegendPanel } from './components/LegendsPanel';
import { ReadOnlyBar } from './components/ReadOnlyBar';
import RulesEngineRunner from './components/RulesEngineRunner';
import { useMetricDerivedState } from './hooks/useMetricDerivedState';
import { useMetricEditorState } from './hooks/useMetricEditorState';
import { useMetricPageMode } from './hooks/useMetricPageMode';
import { useMetricPageSearchParams } from './hooks/useMetricPageSearchParams';
import { parseName, useSaveAsNewMetric } from './hooks/useSaveAsNewMetric';
import { MetricPagePath } from './pageRoutesPaths';
import { shouldLegendPanelExpand } from './utils';
import { formatBuilderFeatureEditMode } from './utils/eventMetadata';
import { hashMetricYamlEditorValueForPreview } from './utils/stateHelpers';
import { PageV2 } from '../../layout/PageV2';
import { MetricBuilderAIAgentSuggestionsBoundary } from './hooks/useMetricBuilderAIAgent';

const REQUEST_LOADER_SPEED = 18; //milliseconds;

export default function MetricPage() {
	return (
		<Provider initialValues={[getInitialDerivedStateAtom(), [IsMetricPageURLBasedAtom, true]]}>
			<MetricBuilderAIAgentSuggestionsBoundary>
				<PageV2 page="Metric Page">
					<RulesEngineRunner>
						<MetricPageGuard />
					</RulesEngineRunner>
				</PageV2>
			</MetricBuilderAIAgentSuggestionsBoundary>
		</Provider>
	);
}

export function MetricPageGuard() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	useMetricBuilder();

	const isEditMetricEnabled = isSightfull2;
	const [{ searchParams }] = useMetricPageSearchParamsSource();

	const toast = useToast();
	const {
		isLoading: isRulesEngineLoading,
		metricNameWithFlavor,
		periodRange,
		objectsTypes,
		errorMessage,
		isFullyDefined,
	} = useMetricDerivedState();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const [isLegendPanelExpanded, setIsLegendPanelExpanded] = useState(shouldLegendPanelExpand());

	const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState<boolean>(false);
	const handleChangeExpandLeftPanel = (newValue: boolean) => {
		setIsLeftPanelExpanded(newValue);
	};

	const { setMetricPageMode, pageModeParam } = useMetricPageMode();
	const isEditMode = useMemo(() => pageModeParam == 'edit', [pageModeParam]);
	const { setMetricYamlEditorHashState } = useUpdatedPreviewHashState();
	const {
		metricEditorState,
		setMetricEditorState,
		hasChangesFromSavedValue,
		hasChangesToPreview,
		isSaveAllowed,
		latestEditorValue,
		metricEditorLoadedState,
	} = useMetricEditorState();
	const { isOpen: isSaveModalOpen, onOpen: onSaveModalOpen, onClose: onSaveModalClose } = useModal();
	const { isOpen: isRenameModalOpen, onOpen: onRenameModalOpen, onClose: onRenameModalClose } = useModal();
	const { invalidateCache } = useInvalidateCache();
	const { navigate } = useNavigation();
	const [upsertMetric, { loading: isUpsertMetricLoading }] = useMutation<
		UpsertMetricMutation,
		UpsertMetricMutationVariables
	>(UpsertMetric);
	const [hasEditorErrors, setHasEditorErrors] = useState(false);
	const { onSave, metricBuilderState: builderState, isCalculatingPreview } = useBuilderDerivedState();

	const [selectedFeature, setSelectedFeature] = useState<EditorFeature>('YAML Builder');
	const isShowingLegendsPanel = !errorMessage;
	const nodeScheme = useNodeScheme({
		metricName: metricNameWithFlavor,
		periods: periodRange.asAbsoluteRange.toMetricPeriods().map((e) => e.id),
		objectsTypes,
	});
	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes,
		readyToFetch: !isRulesEngineLoading,
	});
	const { searchParams: pageSearchParams, setSearchParams } = useMetricPageSearchParams();

	const [progress, setProgress] = useState(100);
	const [isGenAIPopoverOpen, setIsGenAIPopoverOpen] = useState(false);
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);

	const { metricMeta } = useMetricMeta();
	const { reportEvent, wrapWithReport } = useReportEvent({
		metricName: metricNameWithFlavor,
		editMode: formatBuilderFeatureEditMode(selectedFeature),
	});

	const { setIsNavigationBlocked } = useNavigationBlock();

	const investigateModePanelWidth = 400;
	const isReadOnlyBarShown = isEditMode && !hasEditPermission && hasChangesFromSavedValue;
	const readOnlyBarHeight = 60;
	const isPreviewEnabled = hasChangesToPreview && !hasEditorErrors;
	const isYAMLBuilderSelected = selectedFeature === 'YAML Builder';
	const isYAMLEditorSelected = selectedFeature === 'YAML Editor';
	const isFormulaBuilderEnabled = !metricEditorState.isLoading && metricEditorState.kind === 'formula';

	useEffect(() => {
		if (metricEditorState.isLoading) return;
		const interval = setInterval(() => {
			setProgress(progress + 1);
		}, REQUEST_LOADER_SPEED);
		if (progress > 85) clearInterval(interval);

		return () => clearInterval(interval);
	}, [isCalculatingPreview, metricEditorState.isLoading, progress]);
	useEffect(() => {
		// TODO: Move this to the useBuilderDerivedState hook and its onPreview call. Right now this lives here because preview may be triggered in several places
		if (metricEditorState.isLoading) return;
		if (!isCalculatingPreview && progress < 100) {
			setProgress(100);
			setMetricEditorState((s) => ({ ...s, error: errorMessage }));
			if (hasEditPermission) {
				if (errorMessage && isFullyDefined) {
					toast({
						variant: 'error',
						message: 'Preview failed, please review and try again.',
					});
				} else if (!errorMessage) {
					toast({
						variant: 'ok',
						message: (
							<Typography variant="Paragraph14R" color="gray.1000">
								Preview completed. Review the metric and&nbsp;
								<Typography variant="Paragraph14SB" color="gray.1000">
									Save.
								</Typography>
							</Typography>
						),
						duration: 1000,
					});
				}
			}
		}
		if (isCalculatingPreview && progress === 100) {
			setProgress(0);
		}
	}, [
		errorMessage,
		hasEditPermission,
		isCalculatingPreview,
		isFullyDefined,
		metricEditorState.isLoading,
		progress,
		setMetricEditorState,
		toast,
	]);

	const onSaveClick = useCallback(() => {
		onSaveModalOpen();
	}, [onSaveModalOpen]);

	const toggleGenAIPopover = () => {
		setIsGenAIPopoverOpen(!isGenAIPopoverOpen);
	};

	const onPreview = useCallback(() => {
		if (!latestEditorValue || !metricEditorLoadedState?.kind) return;
		const previewValue = singleMetricToFullYaml(latestEditorValue, metricEditorLoadedState?.kind);
		setMetricYamlEditorHashState((s) => ({
			...s,
			requestedPreviewHash: hashMetricYamlEditorValueForPreview(previewValue),
		}));
		setMetricEditorState((s) => ({ ...s, previewValue: previewValue }));
	}, [latestEditorValue, metricEditorLoadedState?.kind, setMetricEditorState, setMetricYamlEditorHashState]);

	const isMetricNameValid = builderState?.name !== undefined && !isUntitledMetric(builderState);
	const isPreviewSuccessful = !isCalculatingPreview && !errorMessage;

	const handleSaveSuccess = useCallback(
		({ redirectTo, additionalSearchParams }: { redirectTo: string; additionalSearchParams?: URLSearchParams }) => {
			if (metricEditorState.isLoading) return;
			toast({
				variant: 'ok',
				message: 'Successfully saved',
			});
			setMetricYamlEditorHashState(() => ({
				requestedPreviewHash: '',
				calculatedRulesEngineHash: '',
			}));
			if (redirectTo) {
				navigate({
					path: redirectTo,
					additionalSearchParams: additionalSearchParams ? additionalSearchParams : searchParams,
				});
			}
		},
		[metricEditorState.isLoading, navigate, searchParams, setMetricYamlEditorHashState, toast]
	);

	const handleSaveFinally = useCallback(() => onSaveModalClose(), [onSaveModalClose]);

	const {
		isInputModalInvalid,
		isSaveAsNewRenameModalOpen,
		isUpsertMetricLoading: isUpsertSaveAsNewMetricLoading,
		onSaveAsNewRenameModalClose,
		setIsInputModalInvalid,
		saveAsNewMetric,
		reservedMetricName,
		isSaveAsNewEnabled,
	} = useSaveAsNewMetric({ selectedFeature, onSuccess: handleSaveSuccess, isPreviewEnabled });

	const onSaveMetricSubmit = useCallback(() => {
		if (isCalculatingPreview || !metricEditorLoadedState) return;
		const lines = metricEditorLoadedState.previewValue.split('\n');
		const correctLines = removeEscaping(lines);

		setIsNavigationBlocked({ isBlocked: false });

		upsertMetric({
			variables: {
				currentMetricName: metricNameWithFlavor,
				metricDefinition: correctLines,
				metricType: metricEditorLoadedState.kind,
			},
		})
			.then((result) => {
				invalidateCache();

				const resultData = result.data?.upsertMetric;
				if (!resultData) return;
				const isRenameOccurred = resultData.upsertMetricAction === 'Rename';

				const additionalSearchParams = new URLSearchParams('pageMode=edit');
				handleSaveSuccess({
					redirectTo: isRenameOccurred ? `/${MetricPagePath}/${resultData.metricName}` : '',
					additionalSearchParams,
				});
				if (!isRenameOccurred && searchParams.get('isSavedAsNew')?.length)
					setSearchParams({ ...pageSearchParams, isSavedAsNew: undefined });
			})
			.then(onSave)
			.catch((error) => {
				if (error.message.includes('metric already exists')) {
					onRenameModalOpen();
					return;
				}
				toast({
					variant: 'error',
					message: 'Save failed, please review and try again.',
				});
			})

			.finally(handleSaveFinally);
	}, [
		isCalculatingPreview,
		metricEditorLoadedState,
		upsertMetric,
		metricNameWithFlavor,
		onSave,
		handleSaveFinally,
		invalidateCache,
		setIsNavigationBlocked,
		searchParams,
		setSearchParams,
		pageSearchParams,
		handleSaveSuccess,
		toast,
		onRenameModalOpen,
	]);

	const closeRenameMetricModal = () => {
		reportEvent({
			event: 'metric-edit-unique-name-modal',
			metaData: { feature: 'YAML Editor', newName: builderState?.name, action: 'confirm' },
		});
		onRenameModalClose();
	};

	return (
		<>
			<Flex
				overflowY={'auto'}
				grow={1}
				direction={'column'}
				height={'100%'}
				paddingBottom={isReadOnlyBarShown ? `${readOnlyBarHeight}px` : 0}
				borderRadius={'16px 0 0 0'}
			>
				{!isEditMetricEnabled && (
					<ResponsiveHeader
						renderItem={(isTiny: boolean) => (
							<Header
								isTiny={isTiny}
								metricMeta={metricMeta}
								onPreview={onPreview}
								selectedFeature={selectedFeature}
								loaderRunning={isCalculatingPreview}
								isSaveAllowed={isSaveAllowed && !hasEditorErrors && isMetricNameValid && isPreviewSuccessful}
								onSaveClick={onSaveClick}
								onSaveAsNewMetricSubmit={saveAsNewMetric}
								isEditMode={isEditMode}
								isUpsertMetricLoading={isUpsertMetricLoading || isUpsertSaveAsNewMetricLoading}
								isSaveAsNewEnabled={isSaveAsNewEnabled}
							/>
						)}
					/>
				)}
				<Flex transition={'0.2s'} grow={1} height={'0'} justify={'space-between'}>
					<LeftExpandCollapsePanel
						handleExpand={handleChangeExpandLeftPanel}
						isLoading={isRulesEngineLoading}
						minWidth={isEditMetricEnabled ? investigateModePanelWidth : 280}
						width={isEditMode ? (isYAMLBuilderSelected ? 400 : window.innerWidth * 0.4) : investigateModePanelWidth}
						isCollapsable={isEditMode}
						isResizeBlocked={!isEditMode || isYAMLBuilderSelected}
						renderItem={(isShowingFullSize: boolean, expandPanel: () => void) => (
							<DrillDownPanel
								shouldPreventToggle={hasEditorErrors && !isYAMLBuilderSelected}
								selectedFeature={selectedFeature}
								setSelectedFeature={setSelectedFeature}
								isPreviewEnabled={isPreviewEnabled}
								isEditMetricEnabled={isEditMetricEnabled}
								setEditMode={(panel: number) => {
									setMetricPageMode(panel ? 'edit' : 'investigate');
									setIsLegendPanelExpanded(!panel);
								}}
								isShowingFullSize={isShowingFullSize}
								isEditMode={isEditMode}
								onModalOpen={modalActions.onOpen}
								setExpanded={expandPanel}
								hasUnsavedChanges={hasChangesFromSavedValue}
								setHasEditorErrors={setHasEditorErrors}
								toggleGenAIPopover={toggleGenAIPopover}
							/>
						)}
					/>

					<Flex width={'100%'} flexDirection={'column'}>
						{isEditMetricEnabled && (
							<Header
								metricMeta={metricMeta}
								onPreview={onPreview}
								selectedFeature={selectedFeature}
								loaderRunning={isCalculatingPreview}
								isSaveAllowed={isSaveAllowed && !hasEditorErrors && isMetricNameValid && isPreviewSuccessful}
								onSaveClick={onSaveClick}
								onSaveAsNewMetricSubmit={saveAsNewMetric}
								isTiny={false}
								isEditMode={isEditMode}
								isUpsertMetricLoading={isUpsertMetricLoading || isUpsertSaveAsNewMetricLoading}
								isSaveAsNewEnabled={isSaveAsNewEnabled}
							/>
						)}
						<Flex overflowY={'auto'} width={'100%'} grow={1}>
							<Flex
								position={'relative'}
								grow={1}
								flexDirection="column"
								paddingTop="24px"
								data-intercom-area={'metric'}
								data-intercom-type={'main'}
								data-intercom-target={'main'}
							>
								<MetricPageBody
									selectedFeature={selectedFeature}
									isLeftPanelExpanded={isLeftPanelExpanded}
									progress={progress}
									isEditMode={isEditMode}
									onModalOpen={modalActions.onOpen}
								/>
							</Flex>
							{isShowingLegendsPanel && (
								<LegendPanel
									isLoading={isRulesEngineLoading}
									isExpanded={isLegendPanelExpanded}
									setIsExpanded={(isExpanded) => {
										reportEvent({ event: 'legend-panel-toggled', metaData: { expanded: isExpanded } });
										setIsLegendPanelExpanded(isExpanded);
									}}
								/>
							)}
						</Flex>
					</Flex>
				</Flex>
				<InteractiveMetricChat
					isGenAIPopoverOpen={isGenAIPopoverOpen}
					setIsGenAIPopoverOpen={setIsGenAIPopoverOpen}
					bottomBarGap={isReadOnlyBarShown ? readOnlyBarHeight : 0}
				/>
			</Flex>
			<FiltersAndBreakdownsModal
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={modalActions.onAddItems}
				nodeScheme={isSightfull2 ? coreNodeScheme : nodeScheme}
				initialFilter={modalState.initialFilter}
			/>

			{isSightfull2 && (
				<ConfirmationModal
					testId={TestIDs.SAVE_METRIC_MODAL}
					modalIcon={<TagIcon24 color={colors.blue[600]} />}
					isOpen={isSaveModalOpen}
					isLoading={isUpsertMetricLoading}
					onSubmit={wrapWithReport(onSaveMetricSubmit, 'metric-edit-save-modal', {
						feature: 'Metric Builder',
						action: 'confirm',
					})}
					modalTitle="Save changes."
					modalText="Changing this metric will affect it and other metrics that depend on it across the entire platform."
					onClose={wrapWithReport(onSaveModalClose, 'metric-edit-save-modal', {
						feature: 'Metric Builder',
						action: 'cancel',
					})}
				/>
			)}
			{isSightfull2 && (
				<>
					<ConfirmationModal
						isWithoutCancel
						submitColorScheme="blue"
						isOpen={isRenameModalOpen}
						onSubmit={closeRenameMetricModal}
						modalTitle="Change metric name."
						modalText={`The metric name “${builderState?.name}” is already in use, please use a unique name.`}
						primaryButtonLabel="Edit name"
						onClose={closeRenameMetricModal}
					/>
					<ConfirmationModal
						setIsInputModalInvalid={setIsInputModalInvalid}
						isInputModalInvalid={isInputModalInvalid}
						isLoading={isUpsertSaveAsNewMetricLoading}
						inputInitialValue={reservedMetricName || parseName(`${metricMeta.name}_copy`)}
						submitColorScheme="blue"
						buttonSize="medium"
						isOpen={isSaveAsNewRenameModalOpen}
						onSubmit={(metricName) => saveAsNewMetric(metricName)}
						modalTitle="The name is already in use."
						modalText={
							<Typography variant="Paragraph14R">
								Enter a different name and&nbsp;
								<Typography variant="Paragraph14SB">save.</Typography>
							</Typography>
						}
						primaryButtonLabel="Save"
						onClose={() => {
							reportEvent({
								event: 'metric-edit-unique-name-modal',
								metaData: { feature: 'YAML Editor', newName: builderState?.name, action: 'confirm' },
							});
							onSaveAsNewRenameModalClose();
						}}
					/>
				</>
			)}
			<ReadOnlyBar
				isReadOnlyBarShown={isReadOnlyBarShown}
				isPreviewButtonVisible={!isFormulaBuilderEnabled || (isFormulaBuilderEnabled && isYAMLEditorSelected)}
				onClick={onPreview}
				metricName={metricNameWithFlavor}
				isEnabled={isPreviewEnabled}
			/>
		</>
	);
}

function MetricPageBody({
	progress,
	isEditMode,
	onModalOpen,
	isLeftPanelExpanded,
	selectedFeature,
}: {
	progress: number;
	isEditMode: boolean;
	onModalOpen: OnModalOpenType;
	isLeftPanelExpanded?: boolean;
	selectedFeature: EditorFeature;
}) {
	const { isCalculatingPreview } = useBuilderDerivedState();

	if (isCalculatingPreview) return <EditorLoader fullHeight maxWidth={510} progress={progress} />;

	return (
		<MetricViewLayout
			selectedFeature={selectedFeature}
			isEditMode={isEditMode}
			onModalOpen={onModalOpen}
			isLeftPanelExpanded={isLeftPanelExpanded}
		/>
	);
}
