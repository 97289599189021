import Page from 'src/layout/Page';
import Box from '@components/Box';
import Flex from '@components/Flex';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { ConnectorsBanner } from './components/ConnectorsBanner';
import { ConnectorsTable } from './components/ConnectorsTable';

export default function ConnectorsPage() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isDataConnectorsPageEnabled = useFeatureFlag('pulse.sightfull2.dataConnectorsPage.enable');

	if (!isSightfull2 || !isDataConnectorsPageEnabled) return null;

	return (
		<Page page="Connectors Page">
			<ConnectorsPageGuard />
		</Page>
	);
}

export function ConnectorsPageGuard() {
	return (
		<Flex direction={'column'} height="100%" padding={'32px'}>
			<Box marginBottom={'36px'}>
				<ConnectorsBanner />
			</Box>
			<Box>
				<ConnectorsTable />
			</Box>
		</Flex>
	);
}
