import Box from '@components/Box';
import Flex from '@components/Flex';
import classes from '../../LeftExpandCollapsePanel.module.scss';
import { Title } from './Title';
import { useReportEvent } from '@services/analytics';
import { Subtitle } from '@components/LeftExpandCollapsePanel/Drilldown/DrillDownCard/Subtitle';
import Tooltip from '@components/Tooltip';

export function DrilldownCard({
	title,
	value,
	icon,
	valueEditor,
	tooltip,
	removeTooltip,
	onRemove,
	onClick,
	eventMetadata,
}: {
	title: string;
	value?: string | null;
	valueEditor?: React.ReactNode;
	tooltip?: string;
	icon: React.ReactNode;
	removeTooltip?: string;
	onRemove?: VoidFunction;
	onClick?: VoidFunction;
	eventMetadata?: object;
}) {
	const { reportEvent } = useReportEvent();

	return (
		<Box paddingBottom={'8px'}>
			<Box style={{ width: '100%' }}>
				<Flex className={classes.card} direction={'column'}>
					<Title
						removeTooltip={removeTooltip}
						icon={icon}
						title={title}
						onRemove={
							onRemove
								? () => {
										reportEvent({ event: 'filters-remove-filter', metaData: eventMetadata });
										onRemove?.();
								  }
								: undefined
						}
					/>
					{value && (
						<Tooltip size={'md'} label={tooltip} variant="fluid" placement={'right'}>
							<Subtitle onClick={onClick} subtitle={value} />
						</Tooltip>
					)}
					{valueEditor}
				</Flex>
			</Box>
		</Box>
	);
}
