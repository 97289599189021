import Center from '@components/Center';
import Typography from '@components/Typography';

export const Operator = ({ operator }: { operator: string }) => {
	return (
		<Center padding={'6px 8px'} cursor={'default'}>
			<Typography variant={'DesktopH10Medium'}>{operator}</Typography>
		</Center>
	);
};
