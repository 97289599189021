import { useMemo, useCallback } from 'react';
import { useParameters } from '../../../../../services/useParameters';
import { DropdownStringEditor } from '../Editors/DropdownStringEditor';
import { EditParameterProps } from './types';
import { getListOptions } from './utils';

export function ParameterSelectOneFromListEditor({ parameter, value, setSelectedValue, onEdit }: EditParameterProps) {
	const parameterDef = parameter.definition;
	const { getParameterDisplayValue } = useParameters();

	const displayValue = useMemo(
		() => getParameterDisplayValue(parameter.definition.name, value) ?? '',
		[getParameterDisplayValue, parameter.definition.name, value]
	);

	const options = useMemo(() => getListOptions(parameterDef.values) ?? [], [parameterDef.values]);

	const valueAsArray = useMemo(() => [value], [value]);
	const onApply = useCallback(
		(values: (string | null)[]) => {
			const value = values[0];
			if (!value) return;
			setSelectedValue(value);
		},
		[setSelectedValue]
	);

	return (
		<DropdownStringEditor
			options={options}
			displayValue={displayValue}
			values={valueAsArray}
			onEdit={onEdit}
			onApply={onApply}
			isSearchable={false}
			isSelectable={true}
			isMultiSelect={false}
			eventName={'parameter-apply-value-from-list'}
			eventMetadata={{ parameterName: parameter.definition.name, parameterValue: value }}
		/>
	);
}
