import Box from '@components/Box';
import { emptyChartOptions } from '@components/Chart/types';
import Flex from '@components/Flex';
import Tooltip from '@components/Tooltip';
import { useModal } from '@hooks/ui/useModal';
import useToast from '@hooks/ui/useToast';
import { getSourcesInfo } from '@hooks/useSourceInfo';
import { PeriodRange } from '@sightfull/period-ranges';
import { Provider } from 'jotai';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import React, { forwardRef, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChartComponentRef } from 'src/common/components/Chart/Chart';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';
import { extractMetricNameAndSearchParams } from 'src/common/utils/signalUtils';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { SignalWidgetMenu } from 'src/pages/DashboardPage/components/Widget/SignalWidget/SignalWidgetMenu';
import {
	INFO_MARKDOWN,
	LEGEND_BAR_HEIGHT,
	LEGEND_BAR_PADDING_BOTTOM,
	LEGEND_BAR_PADDING_X,
	SUCCESS_MARKDOWN,
	WARNING_MARKDOWN,
	WIDGET_HEADER_HEIGHT,
} from 'src/pages/DashboardPage/constants';
import { getInitialDerivedStateAtom } from 'src/pages/MetricPage/atoms/DerivedState';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from 'src/pages/MetricPage/atoms/MetricPageSearchParams';
import { TableRefAtom } from 'src/pages/MetricPage/atoms/TableRef';
import RulesEngineRunner from 'src/pages/MetricPage/components/RulesEngineRunner';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { usePeriodRange } from 'src/pages/MetricPage/hooks/usePeriodRange';
import { MetricThumbnail } from 'src/pages/MetricPage/MetricThumbnail';
import { Filter } from 'src/pages/MetricPage/utils/state.types';
import { useNormalizedSearchParams } from 'src/pages/MetricPage/utils/useNormalizedSearchParams';
import EditSignalModal from 'src/pages/SignalPage/components/EditSignalModal';
import { DeleteDashboardFeedSignal } from 'src/queries/dashboards';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { FeedSignal } from 'src/types/spaces';
import { HorizontalLegend } from '../../HorizontalLegend/HorizontalLegend';
import { Widget, WidgetProps } from '../Widget';
import { SignalWidgetHeader } from './SignalWidgetHeader';

export type ObjectTypesFunction = (objectsTypes: string[]) => void;
export type SignalWidgetProps = {
	onLoaded: ObjectTypesFunction;
	globalFilters: Filter[];
	feedSignal?: FeedSignal;
	globalPeriodRange?: PeriodRange;
	currentDraggingId: string | null;
	setCurrentDraggingId: React.Dispatch<React.SetStateAction<string | null>>;
} & Pick<WidgetProps, 'isEditMode'>;
export const DashboardSignalWidget = forwardRef(function (
	{
		isEditMode,
		feedSignal,
		onLoaded,
		globalFilters,
		globalPeriodRange,
		currentDraggingId,
		setCurrentDraggingId,
	}: SignalWidgetProps,
	chartRef: Ref<ChartComponentRef>
) {
	if (typeof feedSignal?.signal.attachment != 'string') return <>Error: Signal Widget does not have an attachment</>;

	const { metricName, searchParams } = extractMetricNameAndSearchParams(
		decodeURIComponent(feedSignal.signal.attachment)
	);

	return (
		<Provider
			initialValues={[
				[MetricPageSearchParamsAtom, { metricName, searchParams }],
				getInitialDerivedStateAtom(),
				[IsMetricPageURLBasedAtom, false],
				[TableRefAtom, null],
			]}
		>
			<RulesEngineRunner>
				<SignalWidgetWithProvider
					isEditMode={isEditMode}
					ref={chartRef}
					feedSignal={feedSignal}
					onLoaded={onLoaded}
					globalFilters={globalFilters}
					globalPeriodRange={globalPeriodRange}
					currentDraggingId={currentDraggingId}
					setCurrentDraggingId={setCurrentDraggingId}
				/>
			</RulesEngineRunner>
		</Provider>
	);
});
DashboardSignalWidget.displayName = 'SignalWidget';

export const SignalWidgetWithProvider = forwardRef(function (
	{
		isEditMode,
		feedSignal,
		onLoaded,
		globalFilters,
		globalPeriodRange,
		currentDraggingId,
		setCurrentDraggingId,
	}: SignalWidgetProps,
	chartRef: Ref<ChartComponentRef>
) {
	const { reportEvent } = useReportEvent();
	const [deleteSelf, { loading: isDeletionLoading }] = useMutation(DeleteDashboardFeedSignal);
	const [shareSignal, { isLoading: isDuplicationLoading }] = useShareSignalApi();
	const { navigate } = useNavigation();
	const isLoading = isDeletionLoading || isDuplicationLoading;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const {
		isLoading: isMetricLoading,
		objectsTypes,
		metricNameWithFlavor,
		metricDisplayName,
		chartOptions,
		filters,
		displayedLegendItems,
		errorMessage,
		metricSource,
		doesMetricExist,
	} = useMetricDerivedState();
	const { searchParams, setSearchParams } = useMetricPageSearchParams();
	const appliedGlobalFilters = useRef<Filter[]>([]);
	const appliedGlobalPeriod = useRef<PeriodRange | undefined>();
	const [periodRange, setPeriodRange] = usePeriodRange();
	const [{ id: myUserId }] = useUser();
	const signalAttachementSearchParams = useNormalizedSearchParams(
		extractMetricNameAndSearchParams(decodeURIComponent(feedSignal?.signal.attachment ?? ''))
	);
	const sourceInfo = useMemo(() => getSourcesInfo({ source: metricSource }), [metricSource]);
	const SourceLogo = useMemo(() => sourceInfo?.BigLogo, [sourceInfo?.BigLogo]);
	const toast = useToast();
	const {
		isOpen: isCreateDashboardModalOpen,
		onClose: onCreateDashboardModalClose,
		onOpen: onCreateDashboardModalOpen,
	} = useModal();

	useEffect(() => {
		if (!isMetricLoading && objectsTypes) {
			onLoaded(objectsTypes);
		}
	}, [isMetricLoading, objectsTypes, onLoaded]);

	useEffect(() => {
		if (globalFilters == appliedGlobalFilters.current) return;
		const signalFilterParams = signalAttachementSearchParams.filterBy || {};

		const globalFiltersParams = Object.fromEntries(globalFilters.map((f) => [f.key, f.values]));
		setSearchParams({
			...searchParams,
			filterBy: { ...signalFilterParams, ...globalFiltersParams },
		});
		appliedGlobalFilters.current = globalFilters;
	}, [signalAttachementSearchParams.filterBy, globalFilters, searchParams, setSearchParams]);

	const resetToDefaultPeriodSearchParams = useCallback(() => {
		const periodParameters = [
			'relativePeriod',
			'from',
			'to',
			'periodRange',
			'relativePeriodDuration',
			'relativePeriodFrom',
			'relativePeriodTo',
			'selectedXAxisElements',
		];
		const arePeriodSearchParamsEqual = isEqual(
			pick(searchParams, periodParameters),
			pick(signalAttachementSearchParams, periodParameters)
		);
		if (arePeriodSearchParamsEqual) return;
		setSearchParams({
			...searchParams,
			...pick(signalAttachementSearchParams, periodParameters),
		});
	}, [signalAttachementSearchParams, searchParams, setSearchParams]);

	const applyGlobalPeriod = useCallback(() => {
		if (!globalPeriodRange) {
			appliedGlobalPeriod.current = undefined;
			resetToDefaultPeriodSearchParams();
			return;
		}
		const hasGlobalPeriodRangeChanged =
			appliedGlobalPeriod.current?.metricPeriodRangeString != globalPeriodRange?.metricPeriodRangeString;
		const isDifferentPeriodRange = globalPeriodRange?.metricPeriodRangeString != periodRange.metricPeriodRangeString;
		if (!hasGlobalPeriodRangeChanged || !isDifferentPeriodRange) return;

		appliedGlobalPeriod.current = globalPeriodRange;
		setPeriodRange(globalPeriodRange);
	}, [globalPeriodRange, periodRange.metricPeriodRangeString, resetToDefaultPeriodSearchParams, setPeriodRange]);
	useEffect(applyGlobalPeriod, [applyGlobalPeriod]);

	const navigateToSignalPage = () => {
		if (!feedSignal?.signal.attachment) return;
		if (currentDraggingId == feedSignal.signal?.id) {
			setCurrentDraggingId(null);
			return;
		}
		reportEvent({
			event: 'signal-title-clicked',
			metaData: {
				signalId: feedSignal.signal?.id,
				collectionType: 'dashboard',
				attachment: feedSignal.signal.attachment,
				isSameUserSignal: feedSignal.signal.author_id == myUserId,
				isSightfullSignal: feedSignal.signal.author.email?.includes('@sightfull'),
			},
		});
		navigate({ path: `dashboard/${feedSignal.feed_id}/signal/${feedSignal.signal_id}` });
	};

	const duplicateSignal = (newCollection?: { title: string; value: string | null }) => {
		const newCollectionId = newCollection?.value;
		const collectionToPin = newCollectionId ? [newCollectionId] : [feedSignal?.feed_id || ''];
		const signalId = newCollectionId ? feedSignal?.signal_id : '';
		const collectionToUnPin = newCollectionId ? [feedSignal?.feed_id || ''] : [];

		if (!newCollectionId) {
			reportEvent({
				event: 'signal-three-dots-menu-duplicate',
				metaData: { 'widget-type': 'signal', 'collection-type': 'dashboard' },
			});
		}

		shareSignal({
			attachmentContentURL: feedSignal?.signal?.attachment ?? '',
			collectionToPin,
			collectionToUnPin,
			message: feedSignal?.signal.message,
			sentiment: feedSignal?.display_options?.sentiment,
			title: feedSignal?.signal.title ?? metricNameWithFlavor,
			signalId,
		}).then(() => {
			if (newCollection?.title) {
				toast({ variant: 'ok', message: `Successfully moved to ${newCollection?.title}` });
			}
		});
	};

	const moveSignal = (option: { title: string; value: string | null }) => {
		setIsAdditionalMenuOpen(false);
		reportEvent({
			event: 'signal-three-dots-menu-move-to-other-space',
			metaData: { 'widget-type': 'signal', 'collection-type': 'dashboard', 'signal-id': feedSignal?.signal_id },
		});
		duplicateSignal(option);
	};

	const deleteSignal = () => {
		reportEvent({
			event: 'signal-three-dots-menu-remove-from-workspace',
			metaData: { 'widget-type': 'signal', 'collection-type': 'dashboard' },
		});
		deleteSelf({
			variables: {
				feed_id: feedSignal?.feed_id,
				signal_id: feedSignal?.signal_id,
			},
		});
	};

	const isPeriodRangeCorrect =
		!globalPeriodRange || periodRange.metricPeriodRangeString == globalPeriodRange?.metricPeriodRangeString;
	const areGlobalFiltersApplied =
		!globalFilters.length ||
		globalFilters.every((globalFilter) =>
			filters.some(
				(filter) =>
					globalFilter.key == filter.key &&
					globalFilter.values.every((globalFilterValue) => filter.values.includes(globalFilterValue))
			)
		);

	const isDataUnavailableError = (!areGlobalFiltersApplied || !isPeriodRangeCorrect) && !isMetricLoading;

	const isShowingHorizontalLegend =
		feedSignal?.signal.attachment &&
		searchParams.chartType != 'table' &&
		chartOptions.series.length > 0 &&
		searchParams.chartType != 'number' &&
		!isDataUnavailableError &&
		!errorMessage &&
		!(!isMetricLoading && chartOptions == emptyChartOptions);

	const isMetricRemoved = !isMetricLoading && !doesMetricExist;
	const [isAdditionalMenuOpen, setIsAdditionalMenuOpen] = useState(false);

	const moveSignalToCreatedDashboard = (args?: { name: string; value?: string }) => {
		const title = args?.name || '';
		const value = args?.value || '';
		moveSignal({ title, value });
		onCreateDashboardModalClose();
	};

	return (
		<Widget isEditMode={isEditMode} hasShadow={!isMetricRemoved}>
			<Flex width="100%" height="100%" direction={'column'}>
				<Flex>
					<SignalWidgetHeader
						isClickable={!isMetricRemoved}
						signalDescription={feedSignal?.signal.message
							.replaceAll(WARNING_MARKDOWN, '')
							.replaceAll(INFO_MARKDOWN, '')
							.replaceAll(SUCCESS_MARKDOWN, '')}
						onClick={navigateToSignalPage}
						isEditMode={isEditMode}
						title={feedSignal?.signal.title}
						metricTitle={metricNameWithFlavor}
						isLoading={isLoading}
						menu={
							<SignalWidgetMenu
								authorId={feedSignal?.signal.author_id}
								onEdit={navigateToSignalPage}
								onDuplicate={duplicateSignal}
								onRename={() => setIsModalOpen(true)}
								onDelete={deleteSignal}
								canExplore={!isMetricRemoved}
								onMove={moveSignal}
								isAdditionalMenuOpen={isAdditionalMenuOpen}
								setIsAdditionalMenuOpen={setIsAdditionalMenuOpen}
								onAdditionalMenuButtonClick={() => {
									reportEvent({
										event: 'signal-create-new-dashboard-for-moving',
										metaData: {
											'widget-type': 'signal',
											'collection-type': 'dashboard',
											'signal-id': feedSignal?.signal_id,
										},
									});

									onCreateDashboardModalOpen();
								}}
								feedSignal={feedSignal}
							/>
						}
						closeOnBlur={!isAdditionalMenuOpen}
					/>
				</Flex>

				{feedSignal?.signal && (
					<EditSignalModal
						metricDisplayName={metricDisplayName}
						isModalOpen={isModalOpen}
						onModalClose={() => setIsModalOpen(false)}
						signal={feedSignal?.signal}
						collectionType={'dashboard'}
					/>
				)}
				<Flex
					grow={1}
					height={`calc(100% - ${WIDGET_HEADER_HEIGHT + LEGEND_BAR_HEIGHT + LEGEND_BAR_PADDING_BOTTOM}px)`}
				>
					{feedSignal?.signal.attachment && (
						<Box position="relative" width={'100%'} height={'100%'}>
							<MetricThumbnail ref={chartRef} isDataUnavailableError={isDataUnavailableError} />
						</Box>
					)}
				</Flex>
				<Flex
					p={`0 ${LEGEND_BAR_PADDING_X}px ${LEGEND_BAR_PADDING_BOTTOM}px ${LEGEND_BAR_PADDING_X}px`}
					justifyContent={isShowingHorizontalLegend ? 'space-between' : 'flex-end'}
					alignItems={'center'}
				>
					{isShowingHorizontalLegend && (
						<HorizontalLegend
							series={chartOptions.series}
							bubbles={chartOptions.bubbles}
							displayedLegendItems={displayedLegendItems}
						/>
					)}
					{SourceLogo && (
						<Box paddingLeft={'24px'}>
							<Tooltip label={sourceInfo?.name} size="sm">
								<SourceLogo />
							</Tooltip>
						</Box>
					)}
				</Flex>
			</Flex>

			<CollectionUpsertModal
				stopRedirect
				isOpen={isCreateDashboardModalOpen}
				onClose={moveSignalToCreatedDashboard}
				collectionType={'dashboard'}
			/>
		</Widget>
	);
});
SignalWidgetWithProvider.displayName = 'SignalWidgetWithProvider';
