import { Box, CircularProgress, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import ActionTooltip from 'src/common/components/ActionTooltip';
import { Info12 } from 'src/common/components/Icons';
import Input from 'src/common/components/Input';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import useSearchParams from 'src/common/hooks/navigation/useSearchParams';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { UNTITLED_METRIC_DISPLAY } from 'src/lib/metricRules/builder/useMetricBuilder';
import { convertToValidCoreName, removeNonAlphabeticCharacters } from 'src/normalize';
import { EditorFeature } from 'src/pages/MetricPage/components/CalculatePanel/EditPanel';

import { TestIDs } from 'src/common/types/test-ids';
import { CollectionMetadata } from 'src/types/spaces';
import { ViewOnlyLabel } from '../ViewOnlyLabel';

export function TitleWithBreadcrumbs({
	onSubmit,
	isTiny = false,
	title,
	description,
	isEditBlocked = false,
	breadcrumb,
	onRedirect,
	isWithDescription,
	isLoading,
	pageType = 'signal',
	isActiveOnLoad = false,
	shouldActivateEditing = false,
	resetActiveEditing,
	isEditMetric,
	setNewMetricValue,
	onSignalSaved,
	isCreateNewPage,
	selectedFeature,
	isViewOnlyBadgeVisible = false,
	shouldUpdateDisplayName = true,
}: CollectionMetadata & {
	onSubmit: ({
		component,
		title,
		description,
		previousTitle,
		previousDescription,
	}: {
		component: 'title' | 'description';
		title: string;
		description?: string;
		previousTitle: string;
		previousDescription?: string;
	}) => void;
	shouldUpdateDisplayName?: boolean;
	isCreateNewPage?: boolean;
	isTiny?: boolean;
	isEditBlocked: boolean;
	breadcrumb?: string;
	onRedirect?: () => void;
	isWithDescription?: boolean;
	pageType?: string;
	isLoading?: boolean;
	isActiveOnLoad?: boolean;
	shouldActivateEditing?: boolean;
	resetActiveEditing?: VoidFunction;
	setNewMetricValue?: (metricValue: { title: string; description: string }) => void;
	isEditMetric?: boolean;
	selectedFeature?: EditorFeature;
	onSignalSaved?: ({ title, description }: { title: string; description?: string }) => void;
	isViewOnlyBadgeVisible?: boolean;
}) {
	const [formValues, setFormValues] = useState({ title, description });
	const [inputState, setInputState] = useState({ isActiveEdit: isActiveOnLoad, isEditTitle: true });
	const [searchParams, setSearchParams] = useSearchParams();
	const isNewSaved = searchParams.get('isNewSaved') === 'true';
	const isSaveAsNewValue = searchParams.get('isSavedAsNew');
	const isSavedAsNew = isSaveAsNewValue === 'true';
	const isEditNameEnabled = !isEditMetric || isSaveAsNewValue === 'true' || isSaveAsNewValue === 'false';
	const descriptionRef = useRef(null);
	const inputWrapper = useRef<HTMLDivElement>(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [description], undefined, false);
	const { upsertYAMLObjectProperties } = useBuilderDerivedState();

	useEffect(() => setFormValues({ title, description }), [title, description]);

	useEffect(() => {
		if (isEditBlocked) return;
		if (shouldActivateEditing || isNewSaved || isSavedAsNew) {
			setInputState({ isActiveEdit: true, isEditTitle: true });
		}
	}, [shouldActivateEditing, isNewSaved, isSavedAsNew, isEditBlocked]);

	const onSaved = ({ description, title }: { description?: string; title: string }) => {
		const formattedTitle = removeNonAlphabeticCharacters(title)?.trim();
		setNewMetricValue?.({
			description: description || '',
			title: formattedTitle,
		});

		if (isCreateNewPage || isEditMetric) {
			const meta = [];
			if (shouldUpdateDisplayName || inputState.isEditTitle) meta.push({ key: 'display_name', value: formattedTitle });
			if (description) meta.push({ key: 'description', value: description });

			if (!meta.length) return;

			const yamlProperties: { key: string; value: string | { key: string; value: string }[] }[] = [
				{ key: 'meta', value: meta },
			];

			if (isEditNameEnabled) {
				yamlProperties.push({ key: 'name', value: convertToValidCoreName(formattedTitle) });
			}

			upsertYAMLObjectProperties(yamlProperties, { shouldPreviewAfter: selectedFeature !== 'YAML Editor' });
		}

		setFormValues({ title: formattedTitle, description });
	};

	function onSubmitHandler() {
		resetActiveEditing?.();
		if (isSavedAsNew) setSearchParams(new URLSearchParams('&pageMode=edit&isSavedAsNew=false'));
		if (title.trim() === formValues.title.trim() && formValues?.description?.trim() === description?.trim()) {
			setInputState({ ...inputState, isActiveEdit: !formValues?.title?.trim() });
			return;
		}

		const capitalizedTitle = capitalizedFirstLetter(formValues.title);
		onSubmit?.({
			component: inputState.isEditTitle ? 'title' : 'description',
			title: capitalizedTitle,
			previousTitle: title,
			description: formValues.description,
			previousDescription: description,
		});

		if (inputState.isActiveEdit) {
			setInputState({
				...inputState,
				isActiveEdit: false,
			});

			const saveValues = {
				title: !formValues.title.trim() ? UNTITLED_METRIC_DISPLAY : capitalizedTitle,
				description: formValues.description,
			};
			if (pageType === 'signal') onSignalSaved?.(saveValues);
			else onSaved(saveValues);
		}
	}

	const AddDescription = () => {
		if (isEditBlocked) {
			return;
		}
		return (
			<Box color="gray.500" transition={'all 0.2s ease'} _hover={{ color: 'gray.600' }}>
				<Typography
					cursor={'pointer'}
					variant={'DesktopH10Regular'}
					noOfLines={1}
					wordBreak={'break-all'}
					textOverflow="ellipsis"
					onClick={() => setInputState({ isActiveEdit: true, isEditTitle: false })}
				>
					Add description
				</Typography>
			</Box>
		);
	};

	const BreadCrumb = breadcrumb && (
		<Flex gap="8px" alignItems={'center'}>
			<Typography
				display={'block'}
				onClick={onRedirect}
				color={'gray.1100'}
				variant={'DesktopH8Regular'}
				_hover={{ color: 'blue.800' }}
				cursor={'pointer'}
				noOfLines={1}
				textOverflow="ellipsis"
			>
				{breadcrumb}
			</Typography>
			<Typography color={'gray.1100'} variant={'DesktopH8Regular'}>
				/
			</Typography>
		</Flex>
	);

	const { isActiveEdit, isEditTitle } = inputState;

	if (isLoading)
		return (
			<Flex width={'100%'} alignItems="center" gap="8px" flexDirection={'row'}>
				<Flex direction="row" gap="8px" alignContent={'center'} alignItems={'center'}>
					{BreadCrumb}
					<Flex>
						<Input
							color="gray.900"
							backgroundColor={'gray.200'}
							size="inline"
							width={'320px'}
							leftComponent={
								<CircularProgress
									isIndeterminate
									color="gray.900"
									size="16px"
									key={`text-${pageType}-loading-indicator`}
								/>
							}
							value={'Saving'}
						/>
					</Flex>
				</Flex>
			</Flex>
		);

	return (
		<Flex width={'100%'} alignItems="center" gap="8px" flexDirection={'row'}>
			{isActiveEdit ? (
				<Flex
					direction="row"
					gap="8px"
					alignContent={'center'}
					alignItems={'center'}
					data-intercom-area={'main'}
					data-intercom-type={'main'}
					data-intercom-target={'title-with-breadcrumbs'}
				>
					{isEditTitle && BreadCrumb}
					<Flex ref={inputWrapper}>
						<Input
							testId={isEditTitle ? TestIDs.METRIC_TITLE_INPUT : TestIDs.METRIC_DESCRIPTION_INPUT}
							isErasable
							autoFocus
							size="sm"
							width={'320px'}
							placeholder={isEditTitle ? `${capitalizedFirstLetter(pageType)} name` : `Describe this ${pageType}`}
							value={isEditTitle ? formValues.title : formValues.description}
							onEnter={onSubmitHandler}
							onBlur={onSubmitHandler}
							onChange={(val: string) => {
								resetActiveEditing?.();
								if (isEditTitle) setFormValues({ ...formValues, title: val });
								else setFormValues({ ...formValues, description: val.replaceAll('\n', '') });
							}}
						/>
					</Flex>
				</Flex>
			) : (
				<Flex width={'100%'} direction="column">
					<Flex direction="row" gap="8px" alignContent={'center'} alignItems={'center'}>
						{BreadCrumb}
						<Flex
							data-intercom-area={'main'}
							data-intercom-type={'main'}
							data-intercom-target={'title-with-breadcrumbs'}
							maxWidth={'90%'}
							transition={'all 0.2s ease'}
							_hover={{ color: 'blue.800' }}
							alignItems={'center'}
							color="gray.1100"
						>
							{isTiny ? (
								<ActionTooltip
									title={title}
									label={description}
									placement="bottom-start"
									marginTop="10px"
									marginLeft="-2px"
								>
									<Flex flexDirection="row">
										<Typography
											noOfLines={1}
											textOverflow="ellipsis"
											marginRight={'4px'}
											textAlign={'start'}
											wordBreak={'break-all'}
											variant="DesktopH8Medium"
											minWidth={200}
										>
											{title}
										</Typography>
										<Box>
											<Info12 />
										</Box>
									</Flex>
								</ActionTooltip>
							) : (
								<Typography
									onClick={() => {
										if (isEditBlocked) return;
										setInputState({ isActiveEdit: true, isEditTitle: true });
									}}
									cursor={isEditBlocked ? 'default' : 'pointer'}
									variant={'DesktopH8Medium'}
									noOfLines={1}
									wordBreak={'break-all'}
									textOverflow="ellipsis"
								>
									{title || UNTITLED_METRIC_DISPLAY}
								</Typography>
							)}
						</Flex>

						{isWithDescription && (
							<Flex
								flex={1}
								transition={'all 0.2s ease'}
								_hover={{ color: 'gray.800' }}
								alignItems={'center'}
								color="gray.800"
								minW={100}
								width={'auto'}
							>
								{!description && AddDescription()}
								{description && (
									<Tooltip
										wrapperStyle={{ width: 'fit-content', maxHeight: '18px' }}
										backgroundColor={'black'}
										label={isOverflowingDescription && description}
										placement="bottom"
										size="md"
										hasArrow
										wordBreak={'break-word'}
										maxWidth={260}
									>
										<Typography
											onClick={() => {
												if (isEditBlocked) return;
												setInputState({ isActiveEdit: true, isEditTitle: false });
											}}
											cursor={isEditBlocked ? 'default' : 'pointer'}
											variant={'DesktopH10Regular'}
											wordBreak={'break-all'}
											textAlign={'start'}
										>
											<Box noOfLines={1} ref={descriptionRef} textOverflow="ellipsis">
												{description}
											</Box>
										</Typography>
									</Tooltip>
								)}
							</Flex>
						)}
						{(isEditBlocked || isViewOnlyBadgeVisible) && <ViewOnlyLabel pageType={pageType} />}
					</Flex>
				</Flex>
			)}
		</Flex>
	);
}
