import { capitalizedFirstLetter } from 'src/common/utils/format';
import {
	FILTER_EMPTY_STRING_OPTION_NAME,
	FILTER_NULL_OPTION_NAME,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import { Filter } from '@pages/MetricPage/utils/state.types';
import { FiltersAndBreakdownItemType } from '@pages/MetricPage/components/FiltersAndBreakdown/types';

export const getSubstringBeforeDot = (str: string): string => {
	return str.split('.')[0];
};

export const getSubstringAfterDot = (str: string): string => {
	return str.split('.')[1] ? str.split('.')[1] : str;
};

export const getRelationship = (str: string): string => {
	return str.split('>')[1] ? str.split('>')[1] : str;
};

export const formatDimensionLabel = (input: string): string => {
	if (input.charAt(0) !== '$') {
		return input;
	}

	const parts = input.split('.').map((part) => {
		const subParts = part
			.split(/(\$|___|__|_)/)
			.filter((subPart) => subPart && !['$', '___', '__', '_'].includes(subPart));

		return subParts.map((subPart) => capitalizedFirstLetter(subPart)).join(' ');
	});

	return parts.join(' / ');
};

export const replaceLastCommaWithOr = (str: string): string => {
	const lastCommaIndex = str.lastIndexOf(',');
	if (lastCommaIndex === -1) return str;
	return str.slice(0, lastCommaIndex) + ' or' + str.slice(lastCommaIndex + 1);
};

export const formatMetricFilterLabel = (input: string): string => {
	if (input === '') return FILTER_EMPTY_STRING_OPTION_NAME;
	if (input === null) return FILTER_NULL_OPTION_NAME;
	return input;
};

export const getEntityToFetch = ({
	entity,
	filter,
	relationships,
}: {
	entity: string;
	filter?: Filter;
	relationships: FiltersAndBreakdownItemType[];
}) => {
	const parsedEntity = filter?.key.indexOf('.') !== -1 ? getSubstringBeforeDot(filter?.key || '') : entity;
	const parsedEntityThroughRelationship =
		relationships.length > 0
			? getRelationship(relationships.find((item) => item.key.includes(`${parsedEntity}>`))?.key || '')
			: '';

	return parsedEntityThroughRelationship || entity;
};
