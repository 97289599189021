import { Flex } from '@chakra-ui/react';
import { PendingAISuggestion } from '@components/AskAI/hooks/useAIPendingSuggestions';
import Input from '@components/Input';
import { Overlay } from '@components/Overlay';
import { TextArea } from '@components/Textarea';
import { useCallback, useMemo, useState } from 'react';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import { AskAIFieldSuggestor } from 'src/common/components/AskAI/AskAIFieldSuggestor';
import Divider from 'src/common/components/Divider';
import { SelectOption } from 'src/common/components/Select/types';
import Skeleton from 'src/common/components/Skeleton';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { getSourcesInfo } from 'src/common/hooks/useSourceInfo';
import { useEntityCatalogQuery } from 'src/generated/graphql';
import { useSharedMonacoTextFieldProviders } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { getEntity } from 'src/lib/completions/utils/utils';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getEntityCssCompletionTooltip } from 'src/lib/completions/widgetBuilder/entityCompletionItem';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';
import { formatBuilderFeatureEditMode } from 'src/pages/MetricPage/utils/eventMetadata';
import { useReportEvent } from 'src/services/analytics';
import useSearchParams from '../../../../../common/hooks/navigation/useSearchParams';
import { TestIDs } from '../../../../../common/types/test-ids';
import { convertToValidCoreName, removeNonAlphabeticCharacters } from '../../../../../normalize';
import { AIMetricPromptCard } from '../../../../CreateNewMetricPage/components';
import { useMetricBuilderAIAgent } from '../../../hooks/useMetricBuilderAIAgent';
import { ParametersPanel } from '../../Parameters/ParametersPanel';
import { SkeletonBuilder } from '../../SkeletonComponents';
import { EditorFeature } from '../EditPanel';
import { AggregateBuilder } from './AggregateBuilder';
import classes from './BuilderPanel.module.scss';
import { FormulaBuilder } from './FormulaBuilder';
import { SelectLabel } from './components/SelectLabel';

type BuilderMetricMeta = {
	display_name?: string;
	description?: string;
};

export function BuilderPanel({
	selectedFeature,
	isCreateNewPage,
}: {
	selectedFeature: EditorFeature;
	isCreateNewPage: boolean;
}) {
	return (
		<Flex width={'100%'} height={'100%'}>
			<BuilderContent selectedFeature={selectedFeature} isCreateNewPage={isCreateNewPage} />
		</Flex>
	);
}

function BuilderContent({
	selectedFeature,
	isCreateNewPage,
}: {
	selectedFeature: EditorFeature;
	isCreateNewPage: boolean;
}) {
	const { metricBuilderState: builderState, upsertYAMLProperty } = useBuilderDerivedState();
	const { metricEditorState } = useMetricEditorState();
	const { data, loading: isLoadingEntities = true } = useEntityCatalogQuery();
	const entities: SelectOption[] = useMemo(
		() =>
			data?.entityCatalog.map((el) => {
				const sourceInfo = getSourcesInfo({ source: el.schema?.sourceDisplayName });
				return {
					label: el.displayName ?? el.entityName,
					value: el.entityName,
					icon: sourceInfo?.Logo && <sourceInfo.Logo />,
				};
			}) ?? [],
		[data?.entityCatalog]
	);

	function setSelectedEntityHandler(entityOption: SelectOption) {
		if (entityOption) {
			upsertYAMLProperty('entity', entityOption.value);
		}
	}

	const selectedEntity = useMemo(() => {
		if (!builderState?.entity) return;
		return (
			entities.find((el) => el.value === builderState.entity) || {
				label: builderState.entity,
				value: builderState.entity,
			}
		);
	}, [builderState?.entity, entities]);

	return isLoadingEntities || metricEditorState.isLoading ? (
		<Flex flexDirection={'column'} p={'20px'} width={'100%'}>
			<Skeleton className={classes.headerSkeleton} />
			<SkeletonBuilder />
		</Flex>
	) : (
		<BuilderBody
			selectedFeature={selectedFeature}
			setSelectedEntityHandler={setSelectedEntityHandler}
			isCreateNewPage={isCreateNewPage}
			isFieldsDisabled={!selectedEntity}
			entities={entities}
			selectedEntity={selectedEntity}
		/>
	);
}

type BuilderProps = {
	setSelectedEntityHandler: (selectedEntity: SelectOption) => void;
	isFieldsDisabled?: boolean;
	isCreateNewPage: boolean;
	entities: SelectOption[];
	selectedEntity?: SelectOption;
	selectedFeature: EditorFeature;
};
export function BuilderBody({
	setSelectedEntityHandler,
	isFieldsDisabled,
	isCreateNewPage,
	entities,
	selectedEntity,
	selectedFeature,
}: BuilderProps) {
	const {
		metricBuilderState: builderState,
		upsertYAMLProperties,
		upsertYAMLObjectProperties,
	} = useBuilderDerivedState();
	const { metricNameWithFlavor, objectsTypes, parameters } = useMetricDerivedState();
	const [searchParams, setSearchParams] = useSearchParams();
	const isSaveAsNewValue = useMemo(() => searchParams.get('isSavedAsNew'), [searchParams]);
	const isSavedAsNew = isSaveAsNewValue === 'true';
	const syncNameWithDisplayName = isCreateNewPage || isSavedAsNew;

	const { reportEvent } = useReportEvent({
		feature: 'Metric Builder',
		metricName: metricNameWithFlavor,
		editMode: formatBuilderFeatureEditMode(selectedFeature),
	});
	const { semanticDefinitions } = useSemanticDefinitions();

	useSharedMonacoTextFieldProviders({ entity: selectedEntity?.value ?? objectsTypes[0], metric: metricNameWithFlavor });

	const isWarningModalEnabledAggregate =
		builderState?.type == 'aggregate' &&
		(!!builderState?.measure?.length || !!builderState.filters?.length || !!builderState?.x_axis);
	const isWarningModalEnabled =
		builderState?.type === 'formula' ? !!builderState?.formula?.length : isWarningModalEnabledAggregate;

	const [metricMeta, setMetricMeta] = useState<BuilderMetricMeta>({
		display_name: builderState?.meta?.display_name,
		description: builderState?.meta?.description,
	});

	const { latestMetricBuilderSuggetion, isMetricBuilderAIAgentEnabled, isBusyExplicit } = useMetricBuilderAIAgent();

	const displayNameSuggestion = useMemo((): PendingAISuggestion => {
		const displayNameSuggestion = latestMetricBuilderSuggetion?.suggestedMetric?.meta?.display_name
			? removeNonAlphabeticCharacters(latestMetricBuilderSuggetion?.suggestedMetric?.meta?.display_name)?.trim()
			: undefined;
		return {
			field: 'display_name',
			suggestedValue: displayNameSuggestion,
			existingValue: builderState?.meta?.display_name,
		};
	}, [builderState?.meta?.display_name, latestMetricBuilderSuggetion?.suggestedMetric?.meta?.display_name]);

	const entitySuggestion = useMemo((): PendingAISuggestion => {
		const entityFieldAISuggestionDisplay =
			(latestMetricBuilderSuggetion?.suggestedMetric?.entity
				? entities.find((entity) => entity.value === latestMetricBuilderSuggetion?.suggestedMetric?.entity)?.label
				: undefined) ?? undefined;
		return {
			field: 'entity',
			suggestedValue: latestMetricBuilderSuggetion?.suggestedMetric?.entity,
			existingValue: selectedEntity?.value,
			suggestedValueDisplay: entityFieldAISuggestionDisplay,
		};
	}, [entities, latestMetricBuilderSuggetion?.suggestedMetric?.entity, selectedEntity?.value]);

	const descriptionSuggestion = useMemo((): PendingAISuggestion => {
		return {
			field: 'description',
			suggestedValue: latestMetricBuilderSuggetion?.suggestedMetric?.meta?.description,
			existingValue: builderState?.meta?.description,
		};
	}, [builderState?.meta?.description, latestMetricBuilderSuggetion?.suggestedMetric?.meta?.description]);

	const clearFormulaOnSelect = useCallback(
		(entityOption: SelectOption) => {
			upsertYAMLProperties([
				{ key: 'entity', value: entityOption.value },
				{ key: 'formula', value: '' },
			]);
		},
		[upsertYAMLProperties]
	);

	const clearAggregateOnSelect = useCallback(
		(entityOption: SelectOption) => {
			upsertYAMLProperties(
				[
					{ key: 'entity', value: entityOption.value },
					{ key: 'x_axis', value: undefined },
					{ key: 'joins', value: undefined },
					{ key: 'measure', value: undefined },
					{ key: 'filters', value: undefined },
					{ key: 'operation', value: 'count' },
				],
				{ shouldRequestAISuggestions: true, shouldPreviewAfter: true }
			);
		},
		[upsertYAMLProperties]
	);

	const onSelectEntityAndClear = useCallback(
		(entityOption: SelectOption) => {
			setSelectedEntityHandler(entityOption);
			const clearFunction = builderState?.type === 'formula' ? clearFormulaOnSelect : clearAggregateOnSelect;
			clearFunction(entityOption);
		},
		[builderState?.type, clearAggregateOnSelect, clearFormulaOnSelect, setSelectedEntityHandler]
	);

	const onSelectEntity = useCallback(
		(entityOption: SelectOption) => {
			onSelectEntityAndClear(entityOption);
			reportEvent({
				event: 'metric-edit-UI-input-provided',
				metaData: { fieldName: 'entity', input: entityOption.value },
			});
		},
		[onSelectEntityAndClear, reportEvent]
	);

	const onAcceptEntityFieldSuggestion = useCallback(
		(values?: string[]) => {
			const selectedOptions = entities.find((entity) => entity.value === values?.[0]);
			upsertYAMLProperties([{ key: 'entity', value: (selectedOptions ?? entities[0]).value }], {
				shouldPreviewAfter: true,
			});
		},
		[entities, upsertYAMLProperties]
	);

	const onUpsertMetricMeta = useCallback(
		(meta: BuilderMetricMeta, shouldRequestAISuggestions: boolean) => {
			setMetricMeta((cur) => ({ ...cur, ...meta }));
			const displayNameMetaProp = {
				key: 'display_name',
				value: meta.display_name ?? builderState?.meta?.display_name ?? '',
			};
			const descriptionMetaProp = {
				key: 'description',
				value: meta.description ?? builderState?.meta?.description ?? '',
			};
			const props: { key: string; value: string | { key: string; value: string }[] }[] = [
				{ key: 'meta', value: [displayNameMetaProp, descriptionMetaProp] },
			];

			if (meta.display_name && syncNameWithDisplayName) {
				props.push({
					key: 'name',
					value: convertToValidCoreName(meta.display_name),
				});
			}

			const requestAISuggestionsFields = [];
			if (shouldRequestAISuggestions) {
				if (meta.display_name && meta.display_name !== builderState?.meta?.display_name)
					requestAISuggestionsFields.push('display_name');
				if (meta.description && meta.description !== builderState?.meta?.description)
					requestAISuggestionsFields.push('description');
			}

			upsertYAMLObjectProperties(props, {
				shouldPreviewAfter: selectedFeature !== 'YAML Editor',
				requestAISuggestionsForFieldNames: shouldRequestAISuggestions ? requestAISuggestionsFields : undefined,
			});
		},
		[
			builderState?.meta?.description,
			builderState?.meta?.display_name,
			selectedFeature,
			syncNameWithDisplayName,
			upsertYAMLObjectProperties,
		]
	);

	const onSetDisplayName = useCallback(() => {
		if (isSavedAsNew) setSearchParams(new URLSearchParams('&pageMode=edit&isSavedAsNew=false'));
		if (metricMeta.display_name === builderState?.meta?.display_name) return;
		onUpsertMetricMeta({ display_name: metricMeta.display_name }, true);
	}, [builderState?.meta?.display_name, isSavedAsNew, metricMeta.display_name, onUpsertMetricMeta, setSearchParams]);

	const onAcceptMetricDisplayNameSuggestion = useCallback(
		(values?: string[]) => {
			const displayName = values?.[0];
			if (displayName && displayName !== builderState?.meta?.display_name) {
				onUpsertMetricMeta({ display_name: displayName }, false);
			}
		},
		[builderState?.meta?.display_name, onUpsertMetricMeta]
	);

	const onSetDescription = useCallback(() => {
		if (metricMeta.description === builderState?.meta?.description) return;
		onUpsertMetricMeta({ description: metricMeta.description }, true);
	}, [builderState?.meta?.description, metricMeta.description, onUpsertMetricMeta]);

	const onAcceptMetricDescriptionSuggestion = useCallback(
		(values?: string[]) => {
			const description = values?.[0];
			if (description && description !== builderState?.meta?.description) {
				onUpsertMetricMeta({ description: description }, false);
			}
		},
		[builderState?.meta?.description, onUpsertMetricMeta]
	);

	if (!builderState) return <Skeleton />;

	return (
		<Flex width={'100%'} direction="column" overflowY={'auto'} height="100%" overflowX={'visible'} padding={'20px'}>
			{builderState.entity && <AIMetricPromptCard metricType={builderState.type} />}
			<Overlay isActive={isBusyExplicit}>
				{isMetricBuilderAIAgentEnabled && (
					<>
						<SelectLabel marginBottom={'10px'} marginTop={'12px'} color={'gray.1100'} text="Metric name" />
						<AskAIFieldSuggestor
							enabled={builderState.type === 'aggregate'}
							suggestion={displayNameSuggestion}
							onSuggestionAccepted={onAcceptMetricDisplayNameSuggestion}
							reportMetadata={{
								metric: metricNameWithFlavor,
								metricType: latestMetricBuilderSuggetion?.metricType,
								triggerType: latestMetricBuilderSuggetion?.triggerType,
							}}
						>
							<Input
								testId={TestIDs.METRIC_TITLE_INPUT}
								isErasable={false}
								autoFocus={isSavedAsNew}
								size="sm"
								width={'100%'}
								height={'42px'}
								placeholder={'Add name'}
								value={metricMeta.display_name}
								onEnter={onSetDisplayName}
								onBlur={onSetDisplayName}
								onChange={(display_name: string) => {
									setMetricMeta((cur) => ({ ...cur, display_name }));
								}}
								data-intercom-area={'metric'}
								data-intercom-type={'main'}
								data-intercom-target={`builder-text-input-metric-name`}
							/>
						</AskAIFieldSuggestor>
					</>
				)}

				<SelectLabel marginBottom={'10px'} marginTop={'24px'} color={'gray.1100'} text="Entity" />
				<AskAIFieldSuggestor
					enabled={builderState.type === 'aggregate'}
					suggestion={entitySuggestion}
					onSuggestionAccepted={onAcceptEntityFieldSuggestion}
					reportMetadata={{
						metric: metricNameWithFlavor,
						metricType: latestMetricBuilderSuggetion?.metricType,
						triggerType: latestMetricBuilderSuggetion?.triggerType,
					}}
				>
					<AdvancedSelect
						label="Entity"
						isWarningModalEnabled={isWarningModalEnabled}
						onChange={onSelectEntity}
						placeholder="Select entity"
						options={entities}
						controlledValue={selectedEntity}
						initialValue={selectedEntity}
						dataIntercomTarget="entity"
						hoverTooltipBuilder={(entity) => {
							if (!semanticDefinitions || !entity) return;
							const enrichedEntity = getEntity(semanticDefinitions, entity.value);
							if (!enrichedEntity) return;
							return (
								<MonacoTooltipComponent
									tooltipMarkdownString={getEntityCssCompletionTooltip(enrichedEntity, enrichedEntity.name)}
								/>
							);
						}}
					/>
				</AskAIFieldSuggestor>

				<Flex opacity={isFieldsDisabled ? 0.3 : 1} direction={'column'} paddingTop={'12px'}>
					{builderState.type === 'formula' ? (
						<>
							<Divider marginY="20px" direction="horizontal" />
							<FormulaBuilder
								isFieldsDisabled={isFieldsDisabled}
								selectedEntity={selectedEntity}
								selectedFeature={selectedFeature}
							/>
						</>
					) : (
						<>
							<AggregateBuilder
								isFieldsDisabled={isFieldsDisabled}
								selectedEntity={selectedEntity}
								selectedFeature={selectedFeature}
							/>
						</>
					)}

					{isMetricBuilderAIAgentEnabled && (
						<>
							<SelectLabel marginBottom={'10px'} marginTop={'24px'} color={'gray.1100'} text="Description" />
							<AskAIFieldSuggestor
								enabled={builderState.type === 'aggregate'}
								suggestion={descriptionSuggestion}
								onSuggestionAccepted={onAcceptMetricDescriptionSuggestion}
								reportMetadata={{
									metric: metricNameWithFlavor,
									metricType: latestMetricBuilderSuggetion?.metricType,
									triggerType: latestMetricBuilderSuggetion?.triggerType,
								}}
							>
								<TextArea
									testId={TestIDs.METRIC_DESCRIPTION_INPUT}
									autoFocus={false}
									size="sm"
									width={'100%'}
									placeholder={'Add description'}
									value={metricMeta.description}
									height={'72px'}
									color={'gray.1000'}
									borderRadius={'8px'}
									onSubmit={onSetDescription}
									onBlur={onSetDescription}
									onChange={(description: string) => {
										setMetricMeta((cur) => ({ ...cur, description }));
									}}
									data-intercom-area={'metric'}
									data-intercom-type={'main'}
									data-intercom-target={`builder-text-input-description`}
								/>
							</AskAIFieldSuggestor>
						</>
					)}

					{parameters && <ParametersPanel divider={'top'} isShowingFullSize={true} />}
				</Flex>
			</Overlay>
		</Flex>
	);
}
