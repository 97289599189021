import { Box } from '@chakra-ui/react';
import Flex from '@components/Flex';
import useSearchParams from '@hooks/navigation/useSearchParams';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Avatar from 'src/common/components/Avatar/Avatar';
import Button from 'src/common/components/Button';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import Divider from 'src/common/components/Divider';
import TitleWithBreadcrumbs from 'src/common/components/TitleWithBreadcrumbs';
import Tooltip from 'src/common/components/Tooltip';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { TestIDs } from 'src/common/types/test-ids';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { UpsertMetricMutation, UpsertMetricMutationVariables } from 'src/generated/graphql';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { UNTITLED_METRIC_DISPLAY, isUntitledMetric } from 'src/lib/metricRules/builder/useMetricBuilder';
import { removeEscaping } from 'src/models/YamlUtils/yamlUtils';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { useMetricPageSearchParamsSource } from 'src/pages/MetricPage/atoms/MetricPageSearchParams';
import { EditorFeature } from 'src/pages/MetricPage/components/CalculatePanel/EditPanel';
import { CloseButton } from 'src/pages/MetricPage/components/Header/CloseButton';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { formatBuilderFeatureEditMode } from 'src/pages/MetricPage/utils/eventMetadata';
import { UpsertMetric } from 'src/queries/core-manager';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import useNavigation from 'src/services/useNavigation';
import useNavigationBlock from 'src/services/useNavigationBlock';
import { useEnvironment, usePermissionCheck } from 'src/stores/environment';
import { useTenantConfigStore } from 'src/stores/tenantConfig';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { useMetricBuilderAIAgent } from '../../MetricPage/hooks/useMetricBuilderAIAgent';

type HeaderProps = {
	isLoading?: boolean;
	isSaveAllowed?: boolean;
	selectedFeature?: EditorFeature;
};

export const Header = ({ isLoading = false, isSaveAllowed = false, selectedFeature }: HeaderProps) => {
	const { isMetricBuilderAIAgentEnabled } = useMetricBuilderAIAgent();
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const { isOpen: isRenameModalOpen, onOpen: onRenameModalOpen, onClose: onRenameModalClose } = useModal();
	const { metricBuilderState: builderState } = useBuilderDerivedState();
	const { metricEditorState, metricEditorLoadedState } = useMetricEditorState();

	const location = useLocation();
	const isEditBlocked = isMetricBuilderAIAgentEnabled;
	const isNotActiveOnLoad = isEditBlocked || (!hasEditPermission && location.pathname.includes('create-new-metric'));

	const [urlSearchParams] = useSearchParams();
	const metricNameFromSearchParams = removeUnderscoresAndCapitalize(urlSearchParams.get('metricName') || '');

	const newMetricTitle =
		builderState?.meta?.display_name || isNotActiveOnLoad ? 'Untitled metric' : UNTITLED_METRIC_DISPLAY;

	const [newMetricValue, setNewMetricValue] = useState({
		title: metricNameFromSearchParams || newMetricTitle,
		description: '',
	});

	const [{ picture, firstName, lastName }] = useUser();
	const toast = useToast();
	const { navigate } = useNavigation();
	const { reportEvent, wrapWithReport } = useReportEvent({
		feature: 'Metric Builder',
		metricName: newMetricValue.title,
		...(selectedFeature && { editMode: formatBuilderFeatureEditMode(selectedFeature) }),
	});
	const { logoUrl } = useTenantConfigStore();
	const { isMultiTenant } = useEnvironment();
	const [{ searchParams }] = useMetricPageSearchParamsSource();
	const [upsertMetric, { loading: isUpsertMetricLoading }] = useMutation<
		UpsertMetricMutation,
		UpsertMetricMutationVariables
	>(UpsertMetric);
	const fullName = `${firstName} ${lastName}`;
	const { invalidateCache } = useInvalidateCache();

	useEffect(() => {
		const displayName = builderState?.meta?.display_name;
		const name = builderState?.name?.replace(/_/g, ' ');
		const title = capitalizedFirstLetter(
			displayName && displayName !== 'untitled' ? displayName : name || UNTITLED_METRIC_DISPLAY
		);
		if (displayName || name) {
			setNewMetricValue({
				title,
				description: builderState?.meta?.description || '',
			});
		}
	}, [builderState?.meta, builderState?.name]);

	const { setIsNavigationBlocked } = useNavigationBlock();

	const handleSaveSuccess = useCallback(
		({ redirectTo }: { redirectTo: string }) => {
			if (metricEditorState.isLoading) return;
			toast({
				variant: 'ok',
				message: 'Successfully saved',
			});
			if (redirectTo) {
				navigate({
					path: redirectTo,
					additionalSearchParams: searchParams,
				});
			}
		},
		[metricEditorState, searchParams, navigate, toast]
	);

	const onSaveMetricSubmit = useCallback(() => {
		if (metricEditorState.isLoading || !metricEditorLoadedState) return;
		const lines = metricEditorState.previewValue.split('\n');
		const correctLines = removeEscaping(lines);
		upsertMetric({
			variables: {
				currentMetricName: '',
				metricDefinition: correctLines,
				metricType: metricEditorLoadedState.kind,
			},
		})
			.then((result) => {
				invalidateCache();
				const resultData = result?.data?.upsertMetric;
				if (!resultData) return;
				setIsNavigationBlocked({ isBlocked: false });
				setTimeout(() => handleSaveSuccess?.({ redirectTo: `/${MetricPagePath}/${resultData.metricName}` }), 200);
			})

			.catch((error) => {
				if (error.message.includes('metric already exists')) {
					onRenameModalOpen();
					return;
				}
				toast({
					variant: 'error',
					message: 'Save failed, please review and try again.',
				});
			});
	}, [
		metricEditorState,
		metricEditorLoadedState,
		upsertMetric,
		handleSaveSuccess,
		toast,
		onRenameModalOpen,
		invalidateCache,
		setIsNavigationBlocked,
	]);

	const isNameUntitled = isUntitledMetric(builderState);

	return (
		<header data-testid={TestIDs.CREATE_NEW_METRIC_HEADER}>
			<Flex
				alignItems={'center'}
				justifyContent={'space-between'}
				boxShadow={'borderBottom'}
				height={'56px'}
				padding={'12px 32px'}
			>
				<TitleWithBreadcrumbs
					selectedFeature={selectedFeature}
					onSubmit={({ component, title, description, previousTitle, previousDescription }) => {
						const componentName = component === 'title' ? 'name' : 'description';
						reportEvent({
							event: `metric-${componentName}-edited`,
							metaData: {
								feature: 'Metric Builder',
								previousName: previousTitle,
								currentName: title,
								previousDescription: previousDescription,
								currentDescription: description,
							},
						});
					}}
					isCreateNewPage
					isLoading={isLoading}
					pageType="metric"
					title={newMetricValue.title}
					description={newMetricValue.description}
					isWithDescription
					shouldUpdateDisplayName={!!builderState?.meta?.display_name}
					isEditBlocked={true}
					isViewOnlyBadgeVisible={!hasEditPermission}
					setNewMetricValue={setNewMetricValue}
				/>

				{hasEditPermission && (
					<>
						<Tooltip backgroundColor={'black'} size={'md'} variant="fluid" marginTop="4px" label={fullName}>
							<Box
								width={'32px'}
								height="32px"
								border="1px solid transparent"
								borderRadius="100%"
								borderStyle="solid"
								padding="3px"
								transition={'0.2s'}
								_hover={{ border: `1px solid ${colors.blue[700]}` }}
							>
								<Avatar
									imageUrl={isMultiTenant ? logoUrl : picture}
									name={fullName}
									width="24px"
									height="24px"
									shape="round"
								/>
							</Box>
						</Tooltip>
						<Box height="28px">
							<Divider direction="vertical" color={colors.gray[400]} mx="12px" />
						</Box>
					</>
				)}
				<CloseButton variant="ghost" />
				{hasEditPermission && (
					<Tooltip
						placement="left"
						size={'md'}
						label={isNameUntitled ? 'Name cannot be untitled' : !isSaveAllowed && 'Preview your metric before saving.'}
					>
						<Button
							colorScheme={!isSaveAllowed ? 'black' : 'blue'}
							minW={'auto'}
							marginLeft={'12px'}
							variant="solid"
							size="inline"
							isLoading={isUpsertMetricLoading}
							eventMetadata={{ feature: 'Metric Builder', metricName: newMetricValue.title }}
							isDisabled={!isSaveAllowed}
							onClick={wrapWithReport(onSaveMetricSubmit, 'metric-save-clicked')}
							data-intercom-area={'metric'}
							data-intercom-type={'button'}
							data-intercom-target={'save-metric-button'}
						>
							Save metric
						</Button>
					</Tooltip>
				)}
			</Flex>
			<ConfirmationModal
				isWithoutCancel
				submitColorScheme="blue"
				isOpen={isRenameModalOpen}
				onSubmit={() => {
					reportEvent({
						event: 'metric-edit-unique-name-modal',
						metaData: { title: newMetricValue.title, newName: builderState?.name, action: 'confirm' },
					});

					onRenameModalClose();
				}}
				modalTitle="Change metric name."
				modalText={`The metric name “${builderState?.name}” is already in use, please use a unique name.`}
				primaryButtonLabel="Edit name"
				onClose={() => {
					reportEvent({
						event: 'metric-edit-unique-name-modal',
						metaData: { title: newMetricValue.title, newName: builderState?.name, action: 'close' },
					});
					onRenameModalClose();
				}}
			/>
		</header>
	);
};
